import React, { useEffect, useState } from 'react'
import { StyleSheet, View, ScrollView, Pressable } from 'react-native'
import { useNavigation, useRoute } from '@react-navigation/native';
import { useForm, Controller } from 'react-hook-form'
import { HelperText, Button } from 'react-native-paper'

import { theme } from '../../../theme'
import { EventDatePicker } from "./EventDatePicker"
import { RepeatWeekForm } from "./RepeatWeekForm"
import { RepeatMonthForm } from "./RepeatMonthForm"

export const EventDatesForm = ({ auth, navigation, form, onNext, onBack, submitting, error }) => {
  const validate = () => {
    const arr = ['startdate', "starttime", "endtime"]
    if (form.watch("frequency") !== "one-time") {
      arr.push("enddate")
    }
    form.trigger(arr, { shouldFocus: true })
      .then((valid) => {
        if (valid) { onNext() }
      })
  }

  return (
    <View>
      {(form.watch("frequency") == "one-time" || form.watch("frequency") == "daily") &&
        <EventDatePicker form={form} />}

      {form.watch("frequency") == "weekly" &&
        <RepeatWeekForm form={form} />}

      {form.watch("frequency") == "monthly" &&
        <RepeatMonthForm form={form} />}

      <HelperText type={"error"} visible={error || false}>
        {error}
      </HelperText>

      <View style={styles.buttons}>
        <Button onPress={onBack}>
            Back
        </Button>
        <Button onPress={() => validate()} mode='contained' disabled={submitting}>
            {submitting ? "Saving" : "Save"}
        </Button>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-start',
  },
  scroll: {
    padding: 13,
  },
  buttons: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: "100%",
    marginTop: 34
  },
})
