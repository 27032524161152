import { useState, useEffect, useCallback } from 'react'
import { StyleSheet, ScrollView, View } from 'react-native'
import { Appbar, List, Button, Text, Snackbar } from 'react-native-paper'
import { useFocusEffect } from '@react-navigation/native'

import { theme } from '../../theme'

import { Load } from '../../Components/Load'
import { getData } from '../../Components/useData'
import { AcceptInviteButton } from './AcceptInviteButton'
import { CreateGroupModal } from './CreateGroupModal'
import { JoinGroupsModal } from './JoinGroupsModal'
import { JoinRecommend } from './JoinRecommend'

import { GroupLi } from './GroupLi'

export const Groups = ({ auth, navigation, onChangeGroups }) => {
  const [recGroups, setRecGroups] = useState([])
  const [recVisible, setRecVisible] = useState(false)

  const [globalGroups, setGlobal] = useState([])
  const [globalListVisible, setGlobalVisible] = useState(false)

  const [publicGroups, setPublic] = useState([])
  const [publicListVisible, setPublicVisible] = useState(false)

  const [privateGroups, setPrivate] = useState([])
  const [privateCreateVisible, setCreateVisible] = useState(false)

  const [invites, setInvites] = useState([])

  const [load, setLoad] = useState(true)

  const [snack, setSnack] = useState(null)

  const getMyGroups = () => {
    onChangeGroups()
    getData(
      'fit_group_all',
      { userkey: auth?.sub },
      (response) => {
        setInvites(response.invites)
        setPublic(response.friendgroups)
        setPrivate(response.privategroups)
        setGlobal(response.groups)
      },
      () => null,
      () => setLoad(false)
    )
  }

  function componentDidMount() {
    getMyGroups()
  }

  useFocusEffect(
    useCallback(() => {
      getMyGroups()
    }, [])
  )

  const onHideRec = (refreshGroups) => {
    setRecVisible(false)
    if (refreshGroups) {
      getMyGroups()
    }
  }

  const onHidePublic = (refreshGroups) => {
    setPublicVisible(false)
    if (refreshGroups) {
      getMyGroups()
    }
  }

  const onHideGlobal = (refreshGroups) => {
    setGlobalVisible(false)
    if (refreshGroups) {
      getMyGroups()
    }
  }

  const onHidePrivate = () => {
    setCreateVisible(false)
  }

  const onJoin = (count) => {
    setSnack('Successfully joined ' + count + ' group' + (count > 1 ? 's' : ''))
    onHidePublic(true)
    onHideGlobal(true)
    onHideRec(true)
  }

  const onLeave = (msg) => {
    setSnack(msg)
    getMyGroups()
  }

  useEffect(() => {
    auth?.oAuthUser && getMyGroups()
  }, [auth?.oAuthUser, snack])

  return (
    <View style={{ flex: 1, flexDirection: 'column' }}>
      <Appbar.Header
        theme={{
          colors: {
            surface: theme.colors.primary,
            onSurface: theme.colors.onPrimary,
          },
        }}
      >
        <Appbar.Content title='My Groups' />
      </Appbar.Header>
      {load ? (
        <Load />
      ) : (
        <View style={{ flexGrow: 1, flexShrink: 1 }}>
          <ScrollView>
            {invites.length > 0 && (
              <List.Section style={styles.list}>
                <List.Subheader>Invites</List.Subheader>
                {invites.map((g, i) => (
                  <View key={g} style={styles.inviteLi}>
                    <Text style={styles.text} variant='titleMedium'>
                      {g}
                    </Text>
                    <AcceptInviteButton
                      auth={auth}
                      name={g}
                      onAccept={(msg) => setSnack(msg)}
                    />
                  </View>
                ))}
              </List.Section>
            )}
            <List.Section>
              <View style={styles.buttons}>
                <Button
                  style={styles.button}
                  mode='contained'
                  icon='plus-circle'
                  onPress={() => setRecVisible(true)}
                >
                  Recommended Groups
                </Button>
              </View>
            </List.Section>
            <List.Section>
              <List.Subheader>My Public Friend Groups</List.Subheader>
              {publicGroups.length > 0 ? (
                publicGroups.map((g, i) => (
                  <GroupLi
                    key={g + i}
                    auth={auth}
                    name={g}
                    type='public'
                    onSelect={() =>
                      navigation.navigate('Friends', {
                        screen: 'Group',
                        params: { groupName: g, type: 'public' },
                      })
                    }
                    onLeave={onLeave}
                    onError={(msg) => setSnack(msg)}
                  />
                ))
              ) : (
                <List.Item title='You have not joined any groups yet.' />
              )}
              <View style={styles.buttons}>
                <Button
                  style={styles.button}
                  mode='contained'
                  icon='plus-circle'
                  onPress={() => setPublicVisible(true)}
                >
                  Join a public friend group
                </Button>
              </View>
            </List.Section>
            <List.Section style={styles.list}>
              <List.Subheader>My Private Friend Groups</List.Subheader>
              {privateGroups.length > 0 ? (
                privateGroups.map((g, i) => (
                  <GroupLi
                    key={g + i}
                    auth={auth}
                    name={g}
                    type='private'
                    onSelect={() =>
                      navigation.navigate('Friends', {
                        screen: 'Group',
                        params: { groupName: g, type: 'private' },
                      })
                    }
                    onLeave={onLeave}
                    onError={(msg) => setSnack(msg)}
                  />
                ))
              ) : (
                <List.Item title='You are not part of any private groups.' />
              )}
              <View style={styles.buttons}>
                <Button
                  style={styles.button}
                  mode='contained'
                  icon='plus-circle'
                  onPress={() => setCreateVisible(true)}
                >
                  Create your own friend group
                </Button>
              </View>
            </List.Section>

            <List.Section>
              <List.Subheader>My University Groups</List.Subheader>
              {globalGroups.length > 0 ? (
                globalGroups.map((g, i) => (
                  <GroupLi
                    key={g + i}
                    auth={auth}
                    name={g}
                    type='global'
                    onSelect={() =>
                      navigation.navigate('Friends', {
                        screen: 'Group',
                        params: { groupName: g, type: 'university' },
                      })
                    }
                    onLeave={onLeave}
                    onError={(msg) => setSnack(msg)}
                  />
                ))
              ) : (
                <List.Item title='You have not joined any university groups yet.' />
              )}
              <View style={styles.buttons}>
                <Button
                  style={styles.button}
                  mode='contained'
                  icon='plus-circle'
                  onPress={() => setGlobalVisible(true)}
                >
                  Join a university group
                </Button>
              </View>
            </List.Section>

            {recVisible && (
              <JoinRecommend
                auth={auth}
                visible={recVisible}
                hide={onHideRec}
                onJoin={onJoin}
                currentMembership={publicGroups}
                type='rec'
              />
            )}

            {publicListVisible && (
              <JoinGroupsModal
                auth={auth}
                visible={publicListVisible}
                hide={onHidePublic}
                onJoin={onJoin}
                currentMembership={publicGroups}
                type='public'
              />
            )}

            {globalListVisible && (
              <JoinGroupsModal
                auth={auth}
                visible={globalListVisible}
                hide={onHideGlobal}
                onJoin={onJoin}
                currentMembership={globalGroups}
                type='global'
              />
            )}

            <CreateGroupModal
              auth={auth}
              visible={privateCreateVisible}
              hide={onHidePrivate}
              showMessage={(type, msg) => setSnack(msg)}
            />
          </ScrollView>
        </View>
      )}
      <Snackbar visible={snack !== null} onDismiss={() => setSnack(null)}>
        {snack}
      </Snackbar>
    </View>
  )
}

const styles = StyleSheet.create({
  inviteLi: {
    padding: 16,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingRight: 13,
  },
  button: {
    marginTop: 13,
  },
})
