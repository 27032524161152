import { StyleSheet, View, Text } from 'react-native'
import Svg, { Path, Circle } from 'react-native-svg'
import { withOAuth } from 'aws-amplify-react-native'
import { Button } from 'react-native-paper'
import { useTheme } from 'react-native-paper'
import * as Linking from 'expo-linking'
import _ from 'lodash'
const Login = (auth) => {
  const theme = useTheme()

  return (
    <>
      <View style={{ ...styles.page }}>
        <View
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Svg
            viewBox='0 0 395.947 317.814'
            width={80}
            style={{ fill: theme.colors.primary }}
          >
            <Path d='M395.876,155.873v-.141c0-36.412-29.517-65.929-65.929-65.929s-65.929,29.517-65.929,65.929v48.077c0,33.25-29.632,61.726-66.043,61.726s-65.815-36.041-65.815-61.726v-48.077c0-36.412-29.517-65.929-65.929-65.929S.303,119.32.303,155.732c0,131.787-16.68,162.083,197.675,162.083,207.629,0,197.898-8.39,197.898-161.942ZM66.231,199.614c-24.274,0-43.953-19.678-43.953-43.953s19.678-43.953,43.953-43.953,43.953,19.678,43.953,43.953-19.678,43.953-43.953,43.953ZM330.088,199.614c-24.274,0-43.953-19.678-43.953-43.953s19.678-43.953,43.953-43.953,43.953,19.678,43.953,43.953-19.678,43.953-43.953,43.953Z' />
            <Circle cx='198.23' cy='66.538' r='66.538' />
          </Svg>
          <Text style={{ ...styles.text }}>JoinU</Text>
          {/* <Text style={{ ...styles.text2 }}>
            {process.env.SIGNIN_REDIRECT_MOBILE}
          </Text>
          <Text style={{ ...styles.text2 }}>
            {JSON.stringify(Linking.createURL())}
          </Text> */}
        </View>

        <View style={styles.join}>
          <Button
            mode='outlined'
            // icon='minus-circle'
            onPress={() => {
              auth.customProviderSignIn('HarvardKey')
            }}
          >
            LOGIN WITH HARVARDKEY
          </Button>
        </View>
      </View>
    </>
  )
}

export default withOAuth(Login)

const styles = StyleSheet.create({
  page: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  box: {
    flex: 1,
  },
  text: {
    fontSize: 30,
    fontWeight: 600,
    padding: 10,
  },
  text2: {
    fontSize: 12,
    fontWeight: 500,
    padding: 10,
  },
  join: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginTop: 50,
  },
})
