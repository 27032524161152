import React, { useEffect, useState } from 'react'
import { StyleSheet, View, ScrollView, Pressable } from 'react-native'
import { useNavigation, useRoute } from '@react-navigation/native';
import { useForm, Controller } from 'react-hook-form'
import { Text, Button } from 'react-native-paper'
import DropDown from 'react-native-paper-dropdown'

import { theme } from '../../../theme'
import { EventDatePicker } from "./EventDatePicker"

export const DAY_ABBR = ["S", "M", "T", "W", "T", "F", "S"];

export const RepeatWeekForm = ({ auth, navigation, form }) => {

  const [showRepeatDropDown, setShowRepeatDropDown] = useState(false)

  const updateDays = (i) => {
    const repeatdays = form.watch("onweekdays")
    if (repeatdays.includes(i) && repeatdays.length > 1) {
      form.setValue("onweekdays", repeatdays.filter(n => n !== i))
    } else if (!repeatdays.includes(i)) {
      repeatdays.push(i)
      form.setValue("onweekdays", repeatdays)
    }
  }

  return (
    <View>
      <View style={styles.repeat}>
        <Text>Repeat every</Text>
        <DropDown
          dropDownStyle={styles.dropdown}
          mode="outlined"
          visible={showRepeatDropDown}
          showDropDown={() => setShowRepeatDropDown(true)}
          onDismiss={() => setShowRepeatDropDown(false)}
          value={form.watch("weeklyrepeat")}
          setValue={(v) => form.setValue("weeklyrepeat", v)}
          list={[{ label: "1", value: 1 }, { label: "2", value: 2 }, { label: "3", value: 3 }]} />
        <Text>weeks</Text>
      </View>

      <Text>Repeat on</Text>
      <View style={styles.buttons}>
        {DAY_ABBR.map((label, i) => (
          <Button key={label + i}
            mode={form.watch("onweekdays").includes(i) ? "contained" : "contained-tonal"}
            onPress={() => updateDays(i)}>
              {label}
          </Button>
        ))}
      </View>
      
      <EventDatePicker form={form} />
    </View>
  )
}

const styles = StyleSheet.create({
  repeat: {
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: "center",
    gap: 13,
    marginVertical: 21
  },
  scroll: {
    padding: 13,
  },
  buttons: {
    flexGrow: 1,
    flexDirection: 'row',
    marginVertical: 21,
    gap: 13,
  },
})
