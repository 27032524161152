import React, { useEffect, useState } from 'react'
import {
  StyleSheet,
  View,
  ScrollView,
  Pressable,
  BackHandler,
} from 'react-native'
import {
  useNavigation,
  useRoute,
  CommonActions,
} from '@react-navigation/native'
import { useForm, Controller } from 'react-hook-form'
import { Appbar } from 'react-native-paper'

import { theme } from '../../../theme'
import { BasicEventForm } from './BasicEventForm'
import { EventDatesForm } from './EventDatesForm'
import { postData } from '../../../Components/useData'

import { WEEK_NAMES } from './RepeatMonthForm'
import { DAY_ABBR } from './RepeatWeekForm'

export const DAY_NAMES = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

export const EventForm = ({ auth, navigation }) => {
  const route = useRoute()

  const form = useForm({
    defaultValues: {
      titletext: '',
      description: '',
      wheretext: '',
      activity: '',
      frequency: '',
      startdate: new Date(),
      starttime: '',
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      enddate: new Date(),
      endtime: '',
      weeklyrepeat: 1,
      onweekdays: [],
      taglist: [],
    },
  })

  const [stage, setStage] = useState('basic')
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState(false)

  useEffect(
    () =>
      navigation.addListener('beforeRemove', (e) => {
        if (stage === 'basic') {
          return
        }

        e.preventDefault()
        setStage('basic')
      }),
    [navigation, stage]
  )

  useEffect(() => {
    if (form.watch('startdate')) {
      form.setValue('onweekdays', [form.watch('startdate').getDay()])
    } else {
      form.setValue('onweekdays', [new Date().getDay()])
    }
    if (form.watch('frequency') == 'monthly') {
      form.setValue('monthdaytype1', WEEK_NAMES[getWeek()])
      form.setValue('monthdaytype2', [
        DAY_NAMES[form.watch('startdate').getDay()],
      ])
    }
    if (form.watch('starttime')) {
      setTimeForDate('startdate', 'starttime')
    }
    form.setValue('enddate', new Date(form.watch('startdate').getTime()))
  }, [form.watch('frequency'), form.watch('startdate')])

  useEffect(() => {
    if (form.watch('startdate') && form.watch('starttime')) {
      setTimeForDate('startdate', 'starttime')
    }
  }, [form.watch('starttime')])

  useEffect(() => {
    if (form.watch('enddate') && form.watch('endtime')) {
      setTimeForDate('enddate', 'endtime')
    }
  }, [form.watch('endtime')])

  const setTimeForDate = (dateField, timeField) => {
    let date = form.watch(dateField)
    date.setHours(
      parseInt(
        form.watch(timeField).substring(0, form.watch(timeField).indexOf(':'))
      )
    )
    date.setMinutes(
      parseInt(
        form.watch(timeField).substring(form.watch(timeField).indexOf(':') + 1)
      )
    )
  }

  const getWeek = () => {
    let week = Math.ceil(form.watch('startdate').getDate() / 7) - 1
    const nextWeek = new Date(
      form.watch('startdate').getTime() + 7 * 34 * 60 * 60 * 1000
    )
    if (nextWeek.getMonth() !== form.watch('startdate').getMonth()) {
      week = 4
    }
    return week
  }

  function chooseUrl() {
    switch (form.watch('frequency')) {
      case 'daily':
        return 'fit_event_create_daily'
      case 'weekly':
        return 'fit_event_create_weekly'
      case 'monthly':
        return 'fit_event_create_monthly'
      default:
        return 'fit_event_create_onetime'
    }
  }

  const convertDateToParam = (date) => {
    return (
      '' +
      date.getFullYear() +
      (date.getMonth() < 9 ? '0' : '') +
      (date.getMonth() + 1) +
      (date.getDate() < 10 ? '0' : '') +
      date.getDate()
    )
  }

  const convertTimeToParam = (date) => {
    return (
      (date.getHours() < 10 ? '0' : '') +
      date.getHours() +
      '' +
      (date.getMinutes() < 10 ? '0' : '') +
      date.getMinutes()
    )
  }

  const onValidates = (data) => {
    setSubmitting(true)

    var submitData = {
      ...data,
      startdate: convertDateToParam(data.startdate),
      enddate: convertDateToParam(data.enddate),
      starttime: convertTimeToParam(data.startdate),
      endtime: convertTimeToParam(data.enddate),
      onweekdays: data.onweekdays.map((d) => DAY_NAMES[d]),
      group: route.params.groupName,
      userkey: auth.sub,
    }
    try {
      postData(chooseUrl(), submitData, onSuccess, onSubmitError)
    } catch (error) {
      console.error(error)
    }
  }

  const onSuccess = (response) => {
    navToGroup(response.group, 'Successfully created event.')
  }

  const onSubmitError = (error, status) => {
    setSubmitting(false)
    if (status == 504) {
      navToGroup(
        route.params.groupName,
        'Events being created, check back shortly'
      )
    } else if (typeof error === 'string') {
      setError(error)
    }
  }

  const navToGroup = (groupName, msg) => {
    navigation.dispatch((state) => {
      const new_state = state
      const event_route = new_state.routes.find((r) => r.name === 'New Event')
      event_route.params = null
      delete event_route.state
      return CommonActions.reset(new_state)
    })
    navigation.navigate('Friends', {
      screen: 'Group',
      params: { groupName: groupName, snack: msg },
    })
  }

  const onValidateError = (e) => {
    console.log('error')
    console.log(e)
  }

  const validateBasic = () => {
    form
      .trigger(['titletext', 'wheretext', 'frequency', 'activity'], {
        shouldFocus: true,
      })
      .then((valid) => {
        if (valid) {
          setStage('dates')
        }
      })
  }

  return (
    <View style={styles.container}>
      <Appbar.Header
        theme={{
          colors: {
            surface: theme.colors.primary,
            onSurface: theme.colors.onPrimary,
          },
        }}
      >
        <Appbar.BackAction onPress={() => navigation.goBack()} />
        <Appbar.Content title={'New event for ' + route.params.groupName} />
      </Appbar.Header>
      <ScrollView style={styles.scroll}>
        {stage == 'basic' && (
          <BasicEventForm
            auth={auth}
            form={form}
            onNext={validateBasic}
            onBack={() =>
              navigation.navigate('Group', {
                groupName: route.params.groupName,
              })
            }
          />
        )}

        {stage == 'dates' && (
          <EventDatesForm
            form={form}
            onNext={form.handleSubmit(onValidates, onValidateError)}
            onBack={() => setStage('basic')}
            submitting={submitting}
            error={error}
          />
        )}
      </ScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-start',
  },
  scroll: {
    padding: 13,
  },
  buttons: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
  },
})
