import { useState, useEffect } from 'react'
import { View } from 'react-native'
import { List, Text, Button } from 'react-native-paper'
import { getData, postData } from '../../Components/useData'
import { AutocompleteDropdown } from 'react-native-autocomplete-dropdown'

import _ from 'lodash'
export const Tags = ({ auth }) => {
  const [tags, setTags] = useState([])
  const [myTags, setMyTags] = useState([])
  const [selectedItem, setSelectedItem] = useState(null)

  const [expanded, setExpanded] = useState(true)

  const handlePress = () => setExpanded(!expanded)
  useEffect(() => {
    getData(
      'fit_tag_get',
      {
        userkey: auth?.sub,
      },
      (response) => {
        setTags(response)
      }
    )
    getData(
      'fit_account_info',
      {
        userkey: auth?.sub,
      },
      (response) => {
        setMyTags(response.tags)
      }
    )
  }, [])
  return (
    <>
      <List.Section>
        <List.Subheader>My Tags</List.Subheader>
        {myTags.map((tag, index) => {
          return <List.Item title={_.capitalize(tag)} />
        })}
      </List.Section>
      <List.Section>
        {/* <AutocompleteDropdown
          clearOnFocus={false}
          closeOnBlur={true}
          closeOnSubmit={false}
          initialValue={{ id: '2' }}
          onSelectItem={setSelectedItem}
          dataSet={[
            { id: '1', title: 'Alpha' },
            { id: '2', title: 'Beta' },
            { id: '3', title: 'Gamma' },
          ]}
        /> */}
        ;
        <List.Accordion
          title='Add Tags'
          left={(props) => <List.Icon {...props} icon='plus-box' />}
          expanded={expanded}
          onPress={handlePress}
        >
          {tags.map((tag, index) => {
            return (
              <List.Item
                key={index}
                title={_.capitalize(tag)}
                right={(props) => (
                  <Button
                    icon='plus'
                    mode='outlined'
                    onPress={() => {
                      getData(
                        'fit_tag_add',
                        {
                          userkey: auth?.sub,
                          tag: tag,
                        },
                        (response) => {
                          getData(
                            'fit_account_info',
                            {
                              userkey: auth?.sub,
                            },
                            (response) => {
                              setMyTags(response.tags)
                            }
                          )
                        }
                      )
                    }}
                  >
                    Add
                  </Button>
                )}
              />
            )
          })}
        </List.Accordion>
      </List.Section>
    </>
  )
}
