import { useState, useEffect } from 'react'
import { View } from 'react-native'
import { List, Text, Button } from 'react-native-paper'

import { Option } from './Option'
import { postData } from '../../Components/useData'

export const Notifications = ({ auth, setSnack }) => {
  const [email, setEmail] = useState('')
  const [cal, setCal] = useState('')
  const [sms, setSms] = useState('')

  const [emailOn, toggleEmail] = useState(false)
  const [calOn, toggleCal] = useState(false)
  const [smsOn, toggleSms] = useState(false)

  const [updated, setUpdated] = useState(false)

  useEffect(() => {
    console.log('auth updated')
    if (auth?.info !== null) {
      if (auth?.info?.sendemail) {
        setEmail(auth?.info?.sendemail)
        toggleEmail(true)
      } else if (auth?.info?.email) {
        setEmail(auth?.info?.email)
        {
          /* setEmail(auth?.oAuthUser.signInUserSession.idToken.payload.email) */
        }
      }
      if (auth?.info?.sendical) {
        setCal(auth?.info?.sendical)
        toggleCal(true)
      } else if (auth?.info?.email) {
        setCal(auth?.info?.email)
        {
          /* setCal(auth?.oAuthUser.signInUserSession.idToken.payload.email) */
        }
      }
      if (auth?.info.sendsms) {
        setSms(auth?.info?.sendsms)
        toggleSms(true)
      }
    }
  }, [auth])

  const updatePrefs = () => {
    setUpdated('updating')
    const data = {
      userkey: auth?.sub,
    }
    data.sendemail = emailOn ? 'storedemail' : ''
    data.sendical = calOn ? 'storedemail' : ''
    data.sendsms = smsOn ? sms : ''

    postData(
      'fit_notifications_prefs',
      data,
      () => {
        setSnack('Updated notification preferences.')
        setUpdated(false)
      },
      () => {
        setSnack('Something went wrong, please try again.')
        setUpdated(true)
      }
    )
  }

  return (
    <>
      <List.Section>
        <View
          style={{ flex: 1, flexDirection: 'column', paddingHorizontal: 16 }}
        >
          <Text variant='headlineSmall'>Notifications</Text>
          <Text variant='titleMedium'>
            How would you like to receive notifications?
          </Text>
        </View>
      </List.Section>

      <View>
        <Option
          icon='email-outline'
          label='Email'
          value={email}
          isChecked={emailOn}
          onToggle={(val) => {
            toggleEmail(val)
            setUpdated(true)
          }}
          onUpdate={(val) => setEmail(val)}
        />
        <Option
          icon='calendar-blank-outline'
          label='Calendar'
          value={cal}
          isChecked={calOn}
          onToggle={(val) => {
            toggleCal(val)
            setUpdated(true)
          }}
          onUpdate={(val) => setCal(val)}
        />
        <Option
          icon='phone-outline'
          label='SMS'
          value={sms}
          isChecked={smsOn}
          onToggle={(val) => {
            toggleSms(val)
            setUpdated(true)
          }}
          onUpdate={(val) => setSms(val)}
        />
      </View>

      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'flex-end',
          margin: 13,
        }}
      >
        <Button
          style={{}}
          mode='contained'
          disabled={updated !== true}
          onPress={() => updatePrefs()}
        >
          {updated === 'updating' ? 'Updating' : 'Update'}
        </Button>
      </View>
    </>
  )
}
