import React, { FC, ReactElement, useRef, useState } from 'react'
import { FlatList, StyleSheet, TouchableOpacity, Modal, View } from 'react-native'
import { TextInput, Checkbox } from 'react-native-paper'

// Modified from https://blog.logrocket.com/creating-custom-react-native-dropdown/
const Dropdown = ({ label, data, selected, onSelect, error, multi=false }) => {
	const dropdownButton = useRef()
	const textInput = useRef()
	const searchRef = useRef()

	const [search, setSearch] = useState('')
	const [visible, setVisible] = useState(false)

	const [dropdownTop, setDropdownTop] = useState(0)
	const [dropdownLeft, setDropdownLeft] = useState(0)

	React.useEffect(() => {
		if (visible) {
			searchRef.current?.forceFocus()
		} else {
			setTimeout(() => textInput.current?.blur(), 300)
		}
	}, [visible])

	const toggleDropdown = () => {
		if (visible) {
			hideDropdown()
		} else {
			openDropdown()
		}
	}

	const hideDropdown = () => {
		setVisible(false)
	}

	const openDropdown = () => {
		dropdownButton.current.measureInWindow(
			(x: number, y: number, _w: number, h: number) => {
				setDropdownTop(y + h)
				setDropdownLeft(x)
			}
		)
		setVisible(true)
	}

	const filter = (search) => {
		if (!search) {
			return data
		}
		const filtered = data.filter((item) => {
			return (
				(typeof item == 'string' &&
					item.toLowerCase().includes(search.toLowerCase())) ||
				item.label?.toLowerCase().includes(search.toLowerCase())
			)
		})
		return filtered.length !== 0 ? filtered : data
	}

	const renderItem = ({ item }) => (
		<Checkbox.Item
			style={styles.itemContainer}
			label={typeof item == 'string' ? item : item.label}
			labelStyle={styles.item}
			position='leading'
			status={
				selected &&
				((typeof item == 'string' && selected.includes(item)) ||
					item.val === selected)
					? 'checked'
					: 'unchecked'
			}
			onPress={() => {
				!multi ? hideDropdown() : searchRef.current?.focus()
				setSearch('')
				onSelect(typeof item == 'string' ? item : item.val)
			}}
		/>
	)

	const getValue = () => {
		if (!selected || selected.length === 0) {
			return ''
		}
		if (typeof data[0] == 'string') {
			return selected.toString()
		}
		const match = data.find((item) => item.val === selected)
		return match ? match.label : ''
	}

	const renderDropdown = (): ReactElement<any, any> => {
		return (
			<Modal visible={visible} transparent animationType='none'>
				<TouchableOpacity style={styles.overlay} onPress={() => hideDropdown()}>
					<View style={[styles.dropdown, { top: dropdownTop, left: dropdownLeft }]} >
						<FlatList
							data={filter(search)}
							ListHeaderComponent={
								<TextInput
									ref={searchRef}
									style={styles.buttonText}
									label='Search'
									value={search}
									onChangeText={(text) => setSearch(text)}
									error={error}
								/>
							}
							renderItem={renderItem}
							keyExtractor={(item, index) => index.toString()}
						/>
					</View>
				</TouchableOpacity>
			</Modal>
		)
	}

	return (
		<TouchableOpacity
			ref={dropdownButton}
			style={styles.button}
			onPress={toggleDropdown}
		>
			{renderDropdown()}
			<TextInput
				ref={textInput}
				style={styles.buttonText}
				label={label}
				value={getValue()}
				mode='outlined'
				editable={false}
				error={error}
			/>
		</TouchableOpacity>
	)
}

const styles = StyleSheet.create({
	button: {
		flexDirection: 'row',
		alignItems: 'center',
	},
	buttonText: {
		flex: 1,
		pointerEvents: 'none',
	},
	dropdown: {
		position: 'absolute',
		backgroundColor: '#fff',
		width: 'auto',
		borderRadius: 4,
		borderWidth: 1,
		borderColor: 'rgb(133, 115, 112)',
		shadowRadius: 4,
		shadowOpacity: 0.5,
		maxHeight: 200,
	},
	overlay: {
		width: '100%',
		height: '100%',
	},
	itemContainer: {
		padding: 13,
	},
	item: {
		textAlign: 'left',
	},
})

export default Dropdown
