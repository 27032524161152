import { useState, useEffect, useRef } from 'react'
import { StyleSheet, View, Pressable } from 'react-native'
import { DateTime } from 'luxon'
import { useTheme, Card, Text, IconButton } from 'react-native-paper'

import { theme } from '../../../theme'

export const EventLi = ({ auth, onSelect, event }) => {
  function getEndTime() {
    let length =
      Number(event.endtime.substring(0, 2)) -
      Number(event.starttime.substring(0, 2))
    if (length < 0) {
      length =
        24 -
        Number(event.starttime.substring(0, 2)) +
        Number(event.endtime.substring(0, 2))
    }
    return convertToString(
      event.datetime
        .plus({ hours: length })
        .set({ minutes: event.endtime.substring(3) })
    )
  }

  function convertToString(datetime) {
    return datetime.toFormat('h:mm a')
  }
  return (
    <Pressable onPress={onSelect}>
      <View key={event.eventid} style={{ ...styles.li }}>
        <View style={styles.text}>
          <Text variant='titleMedium'>{event.titletext}</Text>
          <Text style={styles.sub} variant='titleSmall'>
            {event.type}
          </Text>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              paddingTop: 10,
              flexWrap: 'wrap',
            }}
          >
            {event.tags?.length > 0 && (
              <>
                <Text variant='titleMedium' style={{ marginRight: 10 }}>
                  {'interests: '}
                </Text>

                {event.tags?.map((d, i) => (
                  <>
                    <Text
                      style={{ ...styles.sub, marginRight: 2 }}
                      variant='titleSmall'
                    >
                      {d + (i < event.tags.length - 1 ? ', ' : '')}
                    </Text>
                  </>
                ))}
              </>
            )}
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.text}>
            <Text variant='titleSmall' style={{ textAlign: 'right' }}>
              {event.datetime.toFormat('MM-dd')}
            </Text>
            <Text variant='titleSmall'>
              {event.datetime.toFormat('h:mm a') + '-' + getEndTime()}
            </Text>
          </View>
          {event.status !== 'cancelled' ? (
            <IconButton icon='chevron-right' />
          ) : (
            <></>
          )}
        </View>
      </View>
    </Pressable>
  )
}

const styles = StyleSheet.create({
  li: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    border: '1px solid #CCCCCC',
    marginVertical: 5,
    padding: 13,
    borderRadius: 8,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  text: {
    paddingVertical: 8,
  },
  sub: {
    color: theme.colors.outline,
  },
})
