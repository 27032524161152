import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { DateTime } from 'luxon'
import { useNavigation, useRoute } from '@react-navigation/native'
import { useForm, Controller } from 'react-hook-form'

import { theme } from '../../../theme'
import { TIMEZONES } from '../../../Components/Timezones'
import FormDropdown from '../../../Components/FormDropdown'
import FormTimeInput from '../../../Components/FormTimeInput'
import FormDateInput from '../../../Components/FormDateInput'

export const EventDatePicker = ({ auth, navigation, form, children }) => {
  const getDateOffset = (timeZone = 'UTC', date = DateTime.now()) => {
    const diffMin = DateTime.now().setZone(timeZone).offset
    return (
      (diffMin >= 0 ? '+' : '-') +
      (Math.abs(Math.round(diffMin / 60)) < 10 ? '0' : '') +
      Math.abs(Math.round(diffMin / 60)) +
      ':' +
      Math.abs(diffMin % 60) +
      (diffMin % 60 === 0 ? '0' : '')
    )
  }

  const getTimeZoneLabel = (timeZone = 'UTC', date = new Date()) => {
    return '(GMT' + getDateOffset(timeZone, date) + ') ' + timeZone
  }

  return (
    <View>
      <FormDateInput
        form={form}
        fieldName='startdate'
        label={
          form.watch('frequency') == 'one-time' ? 'Event date*' : 'Start date*'
        }
        error={form.formState.errors?.startdate}
        startdatelimit={new Date()}
      />

      {children}

      {form.watch('frequency') !== 'one-time' && (
        <FormDateInput
          form={form}
          fieldName='enddate'
          label='End date*'
          error={form.formState.errors?.enddate}
          startdatelimit={form.watch('startdate')}
        />
      )}

      <View style={{ paddingTop: 13 }}>
        <FormDropdown
          form={form}
          fieldName='timezone'
          label='Time zone'
          data={TIMEZONES.map((tz) => {
            return {
              val: tz,
              label: getTimeZoneLabel(tz),
            }
          })}
        />
      </View>

      <View style={{ paddingTop: 13 }}>
        <FormTimeInput
          form={form}
          fieldName='starttime'
          label='Start time*'
          error={form.formState.errors?.starttime}
        />
      </View>

      <View style={{ paddingTop: 13 }}>
        <FormTimeInput
          form={form}
          fieldName='endtime'
          label='End time*'
          error={form.formState.errors?.endtime}
          defaultHour={
            form.watch('starttime') &&
            form
              .watch('starttime')
              .substring(0, form.watch('starttime').indexOf(':'))
          }
        />
      </View>
    </View>
  )
}
