import React, { useEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { useForm, Controller } from 'react-hook-form'
import { TextInput, Text, Button, HelperText } from 'react-native-paper'

import ErrorBoundary from "./ErrorBoundary"
import Dropdown from "./Dropdown"

function FormDropdown({ form, fieldName, label, helper, data, multi=false, required=true, validateFunc }) {

  React.useEffect(() => {
    {/* console.log(form.formState.errors[fieldName]) */}
  }, [form.formState]);

  return (
    <View>
      <ErrorBoundary fallback={<p></p>}>
        <Controller
          name={fieldName}
          control={form.control}
          rules={{ required: required, validate: validateFunc && { custom: validateFunc } }}
          render={({ field }) => 
            <Dropdown {...field}
              label={label}
              data={data}
              selected={form.watch(fieldName)}
              onSelect={(item) => form.setValue(fieldName, item)}
              error={form.formState.errors[fieldName]}
              multi={multi} />
          }
        />
        <HelperText type={form.formState.errors[fieldName] ? "error" : "info"} visible={form.formState.errors[fieldName] || helper}>
          {form.formState.errors[fieldName] ? form.formState.errors[fieldName].message : helper}
        </HelperText>
      </ErrorBoundary>
    </View>)
}

export default FormDropdown