import React, { useEffect, useState } from 'react'
import { StyleSheet, View, Pressable } from 'react-native'
import { useForm, Controller } from 'react-hook-form'
import { TextInput, Text, Button, HelperText } from 'react-native-paper'
import { TimePickerModal } from 'react-native-paper-dates'

import ErrorBoundary from './ErrorBoundary'

function FormTimeInput({
  form,
  fieldName,
  label,
  helper,
  error,
  defaultHour,
  required = true,
  onPress,
}) {
  const [showModal, toggleModal] = React.useState(false)

  const onDismiss = React.useCallback(() => {
    toggleModal(false)
  }, [toggleModal])

  const onConfirm = React.useCallback(
    ({ hours, minutes }) => {
      toggleModal(false)
      form.setValue(fieldName, convertTimeToString(hours, minutes))
    },
    [form, toggleModal]
  )

  const convertTimeToString = (hours, minutes) => {
    return (
      (hours < 10 ? '0' : '') +
      hours +
      ':' +
      (minutes < 10 ? '0' : '') +
      minutes
    )
  }

  return (
    <View>
      <ErrorBoundary fallback={<p></p>}>
        <Controller
          name={fieldName}
          control={form.control}
          rules={{ required: required }}
          render={({ field }) => (
            <Pressable onPress={() => toggleModal(true)} style={styles.button}>
              <TextInput
                style={{ ...styles.buttonText, height: 54 }}
                label={label}
                value={form.watch(fieldName)}
                editable={false}
                mode='outlined'
              />
            </Pressable>
          )}
        />
        <HelperText type={error ? 'error' : 'info'} visible={error || helper}>
          {error ? 'Required' : helper}
        </HelperText>
        <TimePickerModal
          visible={showModal}
          onDismiss={onDismiss}
          onConfirm={onConfirm}
          hours={defaultHour}
        />
      </ErrorBoundary>
    </View>
  )
}

const styles = StyleSheet.create({
  button: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttonText: {
    flex: 1,
    pointerEvents: 'none',
  },
})

export default FormTimeInput
