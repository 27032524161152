import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { useForm } from 'react-hook-form'
import { Button, HelperText } from 'react-native-paper'

import FormTextInput from '../../Components/FormTextInput'
import FormRadio from '../../Components/FormRadio'
import { postData } from '../../Components/useData'
import FriendsTags from './FriendsTags'

export const GroupDetailsForm = ({ auth, onSubmit, onCancel }) => {
  const form = useForm({
    defaultValues: {
      groupname: '',
      description: '',
      invitelist: '',
      taglist: [],
    },
  })

  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState()

  const onValidates = (data) => {
    setSubmitting(true)
    var submitData = {
      ...data,
      userkey: auth.sub,
    }
    if (data.invitelist.length > 0) {
      submitData.invitelist = data.invitelist.replace(/\s+/g, '').split(',')
    }

    postData(
      'fit_groupmgr_group_create',
      submitData,
      () => onSubmit(),
      () => {
        setError('Something went wrong, please try again.')
        setSubmitting(false)
      }
    )
  }

  const onError = (e) => {
    console.log('error')
    console.log(e)
  }

  return (
    <View style={styles.container}>
      <FormRadio
        form={form}
        fieldName='access'
        title='Privacy Settings'
        options={[
          { label: 'public', value: 'open' },
          { label: 'private', value: 'closed' },
        ]}
        error={form.formState.errors?.access}
      />
      <FormTextInput
        form={form}
        fieldName='groupname'
        label='*Name'
        helper='Visible to all members'
        error={form.formState.errors?.groupname}
      />

      <FormTextInput
        form={form}
        fieldName='description'
        label='Description'
        helper='Visible to all members'
        required={false}
      />

      <FormTextInput
        form={form}
        fieldName='invitelist'
        label='Add members'
        helper='Invitees will recieve an email to opt-in.'
        placeholder='Enter harvard emails separated by commas'
        multiline={true}
        required={false}
      />
      <FriendsTags auth={auth} form={form} />

      <HelperText type={'error'} visible={error || false}>
        {error}
      </HelperText>

      <View style={styles.buttons}>
        <Button onPress={onCancel} disabled={submitting} style={styles.button}>
          Cancel
        </Button>
        <Button
          onPress={form.handleSubmit(onValidates, onError)}
          mode='contained'
          disabled={submitting}
          style={styles.button}
        >
          {submitting ? 'Saving' : 'Save'}
        </Button>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-start',
    gap: 21,
  },
  description: {
    fontWeight: '400',
    textAlign: 'center',
    paddingHorizontal: 64,
  },
  buttons: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
  },
  button: {
    flexGrow: 0,
    marginLeft: 13,
  },
})
