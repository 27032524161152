import React, { useEffect, useState } from 'react'
import { StyleSheet, View, ScrollView, Pressable } from 'react-native'
import { useNavigation, useRoute } from '@react-navigation/native';
import { useForm, Controller } from 'react-hook-form'
import { Text, Button } from 'react-native-paper'
import DropDown from 'react-native-paper-dropdown'

import { theme } from '../../../theme'
import { EventDatePicker } from "./EventDatePicker"


export const FULL_DAY_NAMES = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
export const WEEK_NAMES = ["First", "Second", "Third", "Fourth", "Last"];

export const RepeatMonthForm = ({ auth, navigation, form }) => {

  const [showRepeatDropDown, setShowRepeatDropDown] = useState(false)

  const isAmbiguous = () => {
    let week = Math.ceil(form.watch("startdate").getDate()/7) - 1
    const nextWeek = new Date(form.watch("startdate").getTime() + 7*34*60*60*1000)
    return (week === 3 && nextWeek.getMonth() !== form.watch("startdate").getMonth())
  }

  return (
    <View>
      <EventDatePicker form={form}>
        <View style={styles.repeat}>
          {isAmbiguous() ? (<>
            <Text>Will repeat every month on the</Text>
              <DropDown
                dropDownStyle={styles.dropdown}
                mode="outlined"
                visible={showRepeatDropDown}
                showDropDown={() => setShowRepeatDropDown(true)}
                onDismiss={() => setShowRepeatDropDown(false)}
                value={form.watch("monthdaytype1")}
                setValue={(v) => form.setValue("monthdaytype1", v)}
                list={[{ label: WEEK_NAMES[3], value: WEEK_NAMES[3] }, { label: WEEK_NAMES[4], value: WEEK_NAMES[4] }]} />
              <Text>{FULL_DAY_NAMES[form.watch("startdate").getDay()]}</Text>
          </>) : (
            <Text>Will repeat every month on the {form.watch("monthdaytype1")} {FULL_DAY_NAMES[form.watch("startdate").getDay()]}</Text>
          )}
        </View>
      </EventDatePicker>
    </View>
  )
}

const styles = StyleSheet.create({
  repeat: {
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: "center",
    gap: 13,
    marginVertical: 21,
    marginHorizontal: 21
  },
  scroll: {
    padding: 13,
  },
  buttons: {
    flexGrow: 1,
    flexDirection: 'row',
    marginVertical: 21,
    gap: 13,
  },
})
