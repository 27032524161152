import React, { useEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { useForm, Controller } from 'react-hook-form'
import { TextInput, Text, Button, HelperText } from 'react-native-paper'

import ErrorBoundary from "./ErrorBoundary"

function FormTextInput({ form, fieldName, label, helper, placeholder="", error, multiline=false, required=true, onPress }) {

  return (
    <View>
      <ErrorBoundary fallback={<p></p>}>
        <Controller
          name={fieldName}
          control={form.control}
          rules={{ required: required, message: "required" }}
          render={({ field }) => 
            <TextInput {...field}
              style={{ height: (multiline ? 89 : 54) }}
              label={label}
              value={form.watch(fieldName)}
              placeholder={placeholder}
              onChangeText={(text) => form.setValue(fieldName, text)}
              mode="outlined"
              error={error}
              multiline={multiline}
              onFocus={onPress} />
          }
        />
        <HelperText type={error ? "error" : "info"} visible={error || helper}>
          {error ? "Required" : helper}
        </HelperText>
      </ErrorBoundary>
    </View>)
}

export default FormTextInput