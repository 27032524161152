import { useState, useEffect, useRef, useCallback } from 'react'
import { View, StyleSheet } from 'react-native'
import { DateTime } from 'luxon'
import { useTheme } from 'react-native-paper'

import { EventFilters } from './EventFilters'
import { EventList } from './EventList'
import { getData } from '../../Components/useData'
import _ from 'lodash'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import {
  BottomSheetModal,
  BottomSheetModalProvider,
} from '@gorhom/bottom-sheet'

const Tab = createMaterialTopTabNavigator()

export const Feed = ({ auth }) => {
  const theme = useTheme()
  const bottomSheetRef = useRef()

  const [events, setEvents] = useState([])
  const [filteredEvents, setFilteredEvents] = useState([])

  const [startdate, setStart] = useState(new Date())
  const oneweek = new Date()
  oneweek.setDate(oneweek.getDate() + 7)
  const [enddate, setEnd] = useState(oneweek)

  const [selectedGroups, setSelectedGroups] = useState([])
  const [selectedTags, setSelectedTags] = useState([])

  const [load, setLoad] = useState(true)
  const [filterOpen, setFilterOpen] = useState(false)

  const getMyEvents = () => {
    setLoad(true)
    getData(
      'fit_notifications_ndays',
      {
        ndays: 30,
        startdate: convertDateToParam(startdate),
        enddate: convertDateToParam(enddate),
        userkey: auth.sub,
      },
      (response) => {
        let es = response
          .map((d) => d._source)
          .filter((e) => e.status !== 'cancelled')
          .filter((e) => e.occurrence_timestring)
        es = es.map((e) => {
          const year = Number(e.occurrence_timestring.slice(0, 4))
          const month = Number(e.occurrence_timestring.slice(5, 7))
          const day = Number(e.occurrence_timestring.slice(8, 10))
          const hour = Number(e.occurrence_timestring.slice(11, 13))
          const min = Number(e.occurrence_timestring.slice(14, 16))
          return {
            ...e,
            datetime: DateTime.fromObject(
              { year: year, month: month, day: day, hour: hour, minute: min },
              { zone: e.timezone }
            ),
          }
        })
        setEvents(
          es.sort((a, b) => a.datetime.toSeconds() - b.datetime.toSeconds())
        )
      }
    )
  }

  const showFilters = () => {
    setFilterOpen(true)
    bottomSheetRef.current?.present()
  }

  const hideFilters = () => {
    setFilterOpen(false)
    bottomSheetRef.current?.dismiss()
  }

  useEffect(() => {
    auth?.oAuthUser && getMyEvents()
  }, [auth])

  useEffect(() => {
    getFilteredEvents()
  }, [events, selectedGroups, selectedTags, startdate, enddate])

  useEffect(() => {
    if (filteredEvents.length == 0) {
      setTimeout(() => setLoad(false), 2000)
    } else {
      setLoad(false)
    }
  }, [filteredEvents])

  function updateDates(start, end) {
    setStart(start ? start : new Date())
    let oneweek = new Date()
    oneweek.setDate(oneweek.getDate() + 7)
    if (end && end <= start) {
      end = new Date()
      end.setDate(start.getDate() + 1)
    }
    setEnd(end ? end : oneweek)
  }

  // const getFilteredEvents = () => {
  //   setLoad(true)
  //   console.log(events)
  //   setFilteredEvents(
  //     events
  //       ? events.filter(
  //           (e) =>
  //             (selectedGroups.length == 0 ||
  //               selectedGroups.includes(e.group)) &&
  //             e.datetime.toMillis() >= startdate.getTime() &&
  //             e.datetime.toMillis() <= enddate.getTime()
  //         )
  //       : []
  //   )
  // }

  const getFilteredEvents = () => {
    setLoad(true)
    console.log(events)
    setFilteredEvents(
      events
        ? events.filter(
            (e) =>
              (selectedGroups.length == 0 ||
                selectedGroups.includes(e.group)) &&
              (selectedTags.length == 0 ||
                _.intersection(selectedTags, e.tags).length > 0) &&
              e.datetime.toMillis() >= startdate.getTime() &&
              e.datetime.toMillis() <= enddate.getTime()
          )
        : []
    )
  }

  const convertDateToParam = (date) => {
    return (
      '' +
      date.getFullYear() +
      (date.getMonth() < 9 ? '0' : '') +
      (date.getMonth() + 1) +
      (date.getDate() < 10 ? '0' : '') +
      date.getDate()
    )
  }

  const updateSelectedGroups = (item) => {
    if (item == null) {
      setSelectedGroups([])
      return
    }
    const groups = [...selectedGroups]
    if (groups.includes(item)) {
      groups.splice(groups.indexOf(item), 1)
    } else {
      groups.push(item)
    }
    setSelectedGroups(groups)
  }

  const updateSelectedTags = (item) => {
    if (item == null) {
      setSelectedTags([])
      return
    }
    const groups = [...selectedTags]
    if (groups.includes(item)) {
      groups.splice(groups.indexOf(item), 1)
    } else {
      groups.push(item)
    }
    setSelectedTags(groups)
  }

  function getHuman(date) {
    return date.toLocaleDateString('en-US', {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
    })
  }

  function isFiltering() {
    const oneweek = new Date()
    oneweek.setDate(oneweek.getDate() + 7)
    return (
      selectedGroups.length > 0 ||
      selectedTags.length > 0 ||
      getHuman(startdate) !== getHuman(new Date()) ||
      getHuman(enddate) !== getHuman(oneweek)
    )
  }

  return (
    <>
      <BottomSheetModalProvider>
        <Tab.Navigator
          initialRouteName='Events from Your Groups'
          screenOptions={{
            tabBarIndicatorStyle: { backgroundColor: theme.colors.primary },
          }}
        >
          <Tab.Screen name='Your Schedule'>
            {() => (
              <View style={styles.eventpage}>
                <EventList
                  auth={auth}
                  events={filteredEvents.filter(
                    (e) => e.messagetype == 'joined'
                  )}
                  isLoading={load}
                  updateEvents={getMyEvents}
                  start={getHuman(startdate)}
                  end={getHuman(enddate)}
                  toggleFilters={filterOpen ? hideFilters : showFilters}
                  isFiltering={isFiltering()}
                />
              </View>
            )}
          </Tab.Screen>
          <Tab.Screen name='Events from Your Groups'>
            {() => (
              <View style={styles.eventpage}>
                <EventList
                  auth={auth}
                  events={filteredEvents}
                  isLoading={load}
                  updateEvents={getMyEvents}
                  start={getHuman(startdate)}
                  end={getHuman(enddate)}
                  toggleFilters={filterOpen ? hideFilters : showFilters}
                  isFiltering={isFiltering()}
                />
              </View>
            )}
          </Tab.Screen>
        </Tab.Navigator>
        <BottomSheetModal ref={bottomSheetRef} index={0} snapPoints={['60%']}>
          <EventFilters
            auth={auth}
            selectedGroups={selectedGroups}
            onSetSelectedGroups={updateSelectedGroups}
            selectedTags={selectedTags}
            onSetSelectedTags={updateSelectedTags}
            start={startdate}
            end={enddate}
            updateDates={updateDates}
            hideFilters={hideFilters}
            isFiltering={isFiltering()}
          />
        </BottomSheetModal>
      </BottomSheetModalProvider>
    </>
  )
}

const styles = StyleSheet.create({
  eventpage: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
})
