import Animated, {
  useSharedValue,
  useAnimatedStyle,
  withSpring,
  withSequence,
  withRepeat,
} from 'react-native-reanimated'
import { StyleSheet, View } from 'react-native'
import { useEffect } from 'react'
import { useInterval } from 'usehooks-ts'
import { useTheme } from 'react-native-paper'
export const Load = () => {
  const theme = useTheme()
  const offset = useSharedValue(0)
  const animatedStyles = (p) =>
    useAnimatedStyle(() => {
      return {
        transform: [{ translateY: offset.value * p }],
      }
    })

  useEffect(() => {
    offset.value = withRepeat(
      withSequence(
        withSpring(-100, {
          damping: 50,
          stiffness: 100,
        }),
        withSpring(0, {
          damping: 50,
          stiffness: 100,
        })
      ),
      Infinity,
      true
    )
  }, [])

  return (
    <View style={styles.page}>
      <Animated.View
        style={[styles.box, animatedStyles(1), { backgroundColor: '#ADADAD' }]}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  page: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 140,
  },
  box: { width: 20, height: 20, borderRadius: 20 },
})
