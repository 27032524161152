import { StyleSheet, View, Pressable } from 'react-native'
import { Text, IconButton } from 'react-native-paper'

import { theme } from '../../theme'
import { ButtonWithConfirmModal } from '../../Components/ButtonWithConfirmModal'
import { getData, postData } from '../../Components/useData'

export const GroupLi = ({ auth, name, type, onLeave, onSelect, onError }) => {
  function leave(e) {
    if (type == 'global') {
      getData(
        'fit_group_remove',
        { userkey: auth?.sub, group: name },
        () => onLeave('Successfully left ' + name),
        () => onError('Could not leave ' + name)
      )
    } else {
      postData(
        'fit_fgroup_leave',
        { userkey: auth?.sub, grouplist: [name] },
        () => onLeave('Successfully left ' + name),
        () => onError('Could not leave ' + name)
      )
    }
  }

  function ownershipString() {
    if (auth?.info?.owner?.includes(name)) {
      return 'Owner'
    } else if (auth?.info?.delegates?.includes(name)) {
      return 'Event Manager'
    }
    return 'Member'
  }

  return (
    <View style={styles.li}>
      <Pressable onPress={onSelect} style={styles.text}>
        <Text variant='titleMedium'>{name}</Text>
        {ownershipString() && (
          <Text style={styles.sub} variant='titleSmall'>
            {ownershipString()}
          </Text>
        )}
      </Pressable>
      {ownershipString() !== 'Owner' && (
        <ButtonWithConfirmModal
          buttonIcon='close-circle'
          title={`Are you sure you want to leave ${name}?`}
          subtitle={
            type == 'private'
              ? 'This is a private group, to rejoin you will have to be re-invited.'
              : 'You can rejoin at any time.'
          }
          confirmButtonText='Leave'
          onConfirm={leave}
        />
      )}
      {onSelect && <IconButton icon='chevron-right' onPress={onSelect} />}
    </View>
  )
}

const styles = StyleSheet.create({
  li: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    padding: 16,
  },
  text: {
    flexGrow: 1,
    paddingVertical: 8,
  },
  sub: {
    color: theme.colors.outline,
  },
})
