import { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import {
  Portal,
  Modal,
  Text,
  TextInput,
  HelperText,
  Button,
  RadioButton,
} from 'react-native-paper'

import { theme } from '../../../theme'
import { getData, postData } from '../../../Components/useData'

export const InviteModal = ({ auth, groupName, setSnack }) => {
  const [inviteType, setInviteType] = useState('member')
  const [state, setState] = useState('type')

  const [invitelist, setInviteList] = useState('')
  const [inviteError, setInviteError] = useState('')

  const [visible, setVisible] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  function getTitle() {
    switch (inviteType) {
      case 'owner':
        return 'Add Owner'
      case 'manager':
        return 'Add Event Managers'
      case 'member':
      default:
        return 'Add Members'
    }
  }

  function getSubTitle() {
    switch (inviteType) {
      case 'owner':
        return 'Owners can manage owners, managers, members and events'
      case 'manager':
        return 'Managers can manage events, but not change owners or other managers'
      case 'member':
      default:
        return 'Members can see events'
    }
  }

  function getUrl() {
    switch (inviteType) {
      case 'owner':
        return 'fit_groupmgr_owner_add'
      case 'manager':
        return 'fit_groupmgr_delegate_add'
      case 'member':
      default:
        return 'fit_groupmgr_user_invite'
    }
  }

  function setSuccessMsg(list, not_found_users, not_in_group) {
    var str = ''
    if (list && getPositiveCount(list, not_found_users, not_in_group) > 0) {
      switch (inviteType) {
        case 'owner':
          str = 'Invited ' + list + ' to be an owner.'
          break
        case 'manager':
          str =
            'Invited ' +
            getPositiveCount(list, not_found_users, not_in_group) +
            ' user(s) to be a manager.'
          break
        case 'member':
        default:
          str =
            'Invited ' +
            getPositiveCount(list, not_found_users, not_in_group) +
            ' emails'
      }
    }

    if (not_found_users && not_found_users.length > 0) {
      str += (str.length > 0 ? '\n' : '') + 'Could not find ' + not_found_users
    }

    if (not_in_group && not_in_group.length > 0) {
      str +=
        (str.length > 0 ? '\n' : '') +
        not_in_group +
        ' must be member(s) of this group'
    }

    setSnack(str)
  }

  const getPositiveCount = (list, not_found_users, not_in_group) => {
    return (
      list.length -
      (not_found_users ? not_found_users.length : 0) -
      (not_in_group ? not_in_group.length : 0)
    )
  }

  const invite = () => {
    if (!invitelist || invitelist.length == 0) {
      setInviteError('Please enter a list of emails')
      return
    }
    setSubmitting(true)

    const iList = invitelist.replace(/\s+/g, '').split(',')

    var data = {
      userkey: auth?.sub,
      groupname: groupName,
    }

    switch (inviteType) {
      case 'owner':
        data['emailaddress'] = invitelist
        break
      case 'manager':
        data['delegate_emails'] = iList
        break
      case 'member':
      default:
        data['invitelist'] = iList
    }

    if (inviteType == 'owner') {
      getData(
        getUrl(),
        data,
        (response) => {
          reset()
          setSuccessMsg(iList)
        },
        (err) => setInviteError(err),
        () => setSubmitting(false)
      )
    } else {
      console.log(data)
      console.log(getUrl())
      postData(
        getUrl(),
        data,
        (response) => {
          reset()
          setSuccessMsg(
            iList,
            response['not_found_users'],
            response['not_in_group']
          )
        },
        (err) => setInviteError(err),
        () => setSubmitting(false)
      )
    }
  }

  function reset() {
    setInviteError('')
    setVisible(false)
    setState('type')
    setInviteError(null)
    setInviteList('')
  }

  return (
    <>
      <Button
        mode='outlined'
        icon='plus-circle'
        onPress={() => setVisible(true)}
        style={{ marginTop: 20, marginBottom: 20 }}
      >
        Invite people
      </Button>

      <Portal>
        <Modal
          visible={visible}
          onDismiss={() => {
            setState('type')
            setVisible(false)
          }}
          contentContainerStyle={styles.modal}
        >
          <View>
            <Text variant='headlineMedium'>
              {state == 'type' ? 'Select an invitation type' : getTitle()}
            </Text>
            {state !== 'type' && (
              <Text variant='headlineSmall'>{getSubTitle()}.</Text>
            )}

            <View style={{ marginTop: 34 }}>
              {state == 'type' ? (
                <RadioButton.Group
                  onValueChange={(value) => {
                    setState('input')
                    setInviteType(value)
                  }}
                  value={state}
                >
                  {['member', 'manager', 'owner'].map((label) => (
                    <RadioButton.Item
                      key={label}
                      label={label}
                      value={label}
                      position='leading'
                      labelStyle={{
                        textAlign: 'left',
                        textTransform: 'capitalize',
                      }}
                    />
                  ))}
                </RadioButton.Group>
              ) : (
                <>
                  <TextInput
                    label={
                      inviteType !== 'owner'
                        ? 'Enter harvard emails separated by commas'
                        : 'Enter harvard email'
                    }
                    helper='Invitees will recieve an in-app invite to opt-in.'
                    // value={invitelist}
                    onChangeText={(text) => setInviteList(text)}
                    mode='outlined'
                    error={inviteError}
                    multiline={inviteType !== 'owner'}
                    style={inviteType !== 'owner' && { height: 89 }}
                  />
                  <HelperText type='error' visible={inviteError}>
                    {inviteError}
                  </HelperText>
                </>
              )}
              <View style={styles.buttons}>
                <Button
                  style={styles.button}
                  onPress={() =>
                    state !== 'type' ? setState('type') : setVisible(false)
                  }
                  disabled={submitting}
                >
                  {state !== 'type' ? 'Back' : 'Cancel'}
                </Button>
                {state !== 'type' && (
                  <Button
                    style={styles.button}
                    mode='contained'
                    onPress={invite}
                    disabled={invitelist.length === 0 || submitting}
                  >
                    {submitting ? 'Inviting' : 'Invite'}
                  </Button>
                )}
              </View>
            </View>
          </View>
        </Modal>
      </Portal>
    </>
  )
}

const styles = StyleSheet.create({
  modal: {
    padding: 21,
    margin: 21,
    marginTop: -160,
    justifyContent: 'flex-start',
    backgroundColor: theme.colors.background,
    borderRadius: 25,
  },

  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 21,
  },
  button: {
    flexGrow: 0,
    marginLeft: 13,
  },
})
