import React, { useEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { useForm, Controller } from 'react-hook-form'
import { Text, RadioButton, HelperText } from 'react-native-paper'

import ErrorBoundary from "./ErrorBoundary"

function FormRadio({ form, fieldName, title, subtitle, options, helper, error, required=true, onPress }) {

	return (
		<ErrorBoundary fallback={<p></p>}>
			<View>
				<View style={{ flexDirection: "column"}}><>
					{title && 
						<Text variant='labelLarge' style={{ paddingTop: 13 }}>
							{title}
						</Text>}
					{subtitle && 
						<Text variant='labelMedium'>
							Public groups are visible to everyone, private groups are invite only
						</Text>}
				</></View>
				<Controller
					name={fieldName}
					control={form.control}
					rules={{ required: required }}
					render={({ field }) => 
						<RadioButton.Group
							onValueChange={(value) => form.setValue(fieldName, value)}
							value={form.watch(fieldName)}>
								{options.map((option, i) => (
									<RadioButton.Item
										key={typeof option == 'string' ? option : option.label}
										label={typeof option == 'string' ? option : option.label}
										value={typeof option == 'string' ? option : option.value}
										position='leading'
										labelStyle={{ textAlign: 'left', textTransform: 'capitalize' }}
									/>
								))}
						</RadioButton.Group>
					}
				/>
				<HelperText type={error ? "error" : "info"} visible={error || helper}>
					{error ? "Required" : helper}
				</HelperText>
			</View>
		</ErrorBoundary>)
}

export default FormRadio