import { useState, useEffect, useCallback } from 'react'
import { useNavigation, CommonActions } from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'

import { Groups } from './Groups'
import { GroupDetail } from './GroupDetails/GroupDetail'
import { EventForm } from './EventCreation/EventForm'

const Stack = createNativeStackNavigator()

export const Friends = ({ navigation, auth, onChangedGroups }) => {
  useEffect(() => {
    // Remove the state when navigating to other main nav so that we return to root groups list when coming back here.
    const unsubscribe = navigation.addListener('blur', () => {
      navigation.dispatch((state) => {
        const new_state = state
        const friend_route = new_state.routes.find((r) => r.name === 'Friends')
        friend_route.params = null
        delete friend_route.state
        return CommonActions.reset(new_state)
      })
    })

    return unsubscribe
  }, [navigation])

  return (
    <Stack.Navigator
      initialRouteName='Groups'
      screenOptions={{ headerShown: false }}
    >
      <Stack.Screen name='Groups'>
        {(props) => (
          <Groups {...props} auth={auth} onChangeGroups={onChangedGroups} />
        )}
      </Stack.Screen>
      <Stack.Screen name='Group'>
        {(props) => <GroupDetail {...props} auth={auth} />}
      </Stack.Screen>
      <Stack.Screen name='New Event'>
        {(props) => <EventForm {...props} auth={auth} />}
      </Stack.Screen>
    </Stack.Navigator>
  )
}
