import React, { useEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { Button } from 'react-native-paper'

import FormTextInput from '../../../Components/FormTextInput'
import FormRadio from '../../../Components/FormRadio'
import FormDropdown from '../../../Components/FormDropdown'
import { getData } from '../../../Components/useData'

import FriendsTags from '../FriendsTags'

export const BasicEventForm = ({ auth, navigation, form, onNext, onBack }) => {
  const [activities, setActivities] = useState([])

  const getActivities = () => {
    getData('fit_activity_directory', { userkey: auth?.sub }, (data) =>
      setActivities(data)
    )
  }

  useEffect(() => getActivities(), [auth])

  return (
    <View style={styles.container}>
      <FormTextInput
        form={form}
        fieldName='titletext'
        label='Name*'
        error={form.formState.errors?.titletext}
      />

      <FormTextInput
        form={form}
        fieldName='wheretext'
        label='Location*'
        placeholder='Describe the location'
        error={form.formState.errors?.wheretext}
      />

      <FormTextInput
        form={form}
        fieldName='description'
        label='Description'
        required={false}
      />

      {/* <FormDropdown
        form={form}
        fieldName='activity'
        label='Activity type'
        data={activities}
        required={false}
        validateFunc={(v) =>
          !v ||
          activities.includes(v) ||
          'Activty must match one of the provided values'
        }
      /> */}
      <FriendsTags auth={auth} form={form} />

      <FormRadio
        form={form}
        fieldName='frequency'
        title='Event frequency'
        options={['one-time', 'daily', 'weekly', 'monthly']}
        error={form.formState.errors?.frequency}
      />

      <View style={styles.buttons}>
        <Button onPress={onBack}>Cancel</Button>
        <Button onPress={onNext} mode='contained'>
          Next: Pick date and time
        </Button>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-start',
  },
  scroll: {
    padding: 13,
  },
  buttons: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
  },
})
