import { useState, useEffect, useRef } from 'react'
import { StyleSheet, View, Linking } from 'react-native'
import { DateTime } from 'luxon'

import { useTheme, Card, Text, Button } from 'react-native-paper'
import { JoinButton } from './JoinButton'
import { Attendees } from './Attendees'

import { theme } from '../../theme'

export const Event = ({ auth, event, attendees, updateEvents }) => {
  let [open, setOpen] = useState(false)

  function getStartTime() {
    return convertToString(event.datetime)
  }

  function getEndTime() {
    let length =
      Number(event.endtime.substring(0, 2)) -
      Number(event.starttime.substring(0, 2))
    if (length < 0) {
      length =
        24 -
        Number(event.starttime.substring(0, 2)) +
        Number(event.endtime.substring(0, 2))
    }
    return convertToString(
      event.datetime
        .plus({ hours: length })
        .set({ minutes: event.endtime.substring(3) })
    )
  }

  function convertToString(datetime) {
    return datetime.toFormat('hh:mm a')
  }

  return (
    <>
      <Card key={event.eventid} style={styles.outer}>
        <Card.Title
          title={event.titletext}
          titleVariant='titleLarge'
          titleNumberOfLines={2}
          subtitle={event.wheretext}
          subtitleVariant='bodyMedium'
          titleStyle={{
            marginTop: 10,
            marginBottom: 8,
          }}
          rightStyle={{ ...styles.title, paddingBottom: 42, paddingRight: 18 }}
          right={() => (
            <>
              <Text
                variant='titleMedium'
                style={event.status == 'cancelled' && styles.strike}
              >
                {getStartTime() + ' - ' + getEndTime()}
              </Text>
              {event.status == 'cancelled' && (
                <Text variant='titleMedium' style={styles.cancelled}>
                  Cancelled
                </Text>
              )}
            </>
          )}
        />
        <Card.Content>
          <View style={{ ...styles.content }}>
            {open && (
              <>
                <Text style={{ marginVertical: 21 }}>{event.description}</Text>
                {event.activity && (
                  <Text style={{ marginBottom: 13 }}>
                    {'Activity: ' + event.activity}
                  </Text>
                )}
                <Text style={{ marginBottom: 13 }}>
                  {'Frequency: ' + event.type}
                </Text>
                <Text style={{ marginBottom: 13 }}>
                  {'Group: ' + event.group}
                </Text>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    flexWrap: 'wrap',
                  }}
                >
                  <Text>{'interests: '}</Text>
                  {event.tags?.length == 0 && (
                    <Text style={{ marginBottom: 13 }}>{'none'}</Text>
                  )}
                  {event.tags?.map((d, i) => (
                    <Text style={{ ...styles.sub, marginRight: 2 }}>
                      {d + (i < event.tags.length - 1 ? ', ' : '')}
                    </Text>
                  ))}
                </View>

                <View style={{ ...styles.footer, marginTop: 13 }}>
                  <View></View>
                  <Button
                    onPress={() =>
                      Linking.openURL(
                        'mailto:contact@joinu.org?subject=Report about event: ' +
                          event.eventid
                      )
                    }
                  >
                    Report Event
                  </Button>
                </View>
              </>
            )}

            <Text style={{ ...styles.link }} onPress={() => setOpen(!open)}>
              {open ? 'Hide details' : 'Show details'}
            </Text>
            <View style={{ ...styles.footer }}>
              <Attendees auth={auth} event={event} attendees={attendees} />
              <JoinButton auth={auth} event={event} onJoined={updateEvents} />
            </View>
          </View>
        </Card.Content>
      </Card>
    </>
  )
}

const styles = StyleSheet.create({
  outer: {
    marginHorizontal: 13,
    marginVertical: 8,
  },
  title: {
    padding: 21,
    height: '100%',
  },
  content: {
    lineHeight: 2,
  },
  time: {
    fontWeight: 600,
  },
  link: {
    color: theme.colors.primary,
    textDecorationLine: 'underline',
    marginTop: 13,
  },
  cancelled: {
    color: theme.colors.error,
  },
  strike: {
    textDecorationLine: 'line-through',
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 21,
    alignItems: 'flex-end',
  },
})
