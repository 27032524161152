import { SafeAreaView, StyleSheet, View } from 'react-native'
import { Appbar } from 'react-native-paper'
import { theme } from '../../theme'
import { Feed } from './Feed'

export const Home = ({ auth }) => {
  return (
    <View style={{ flex: 1, flexDirection: 'column' }}>
      <Appbar.Header
        theme={{
          colors: {
            surface: theme.colors.primary,
            onSurface: theme.colors.onPrimary,
          },
        }}
      >
        <Appbar.Content title='JoinU' />
      </Appbar.Header>
      <Feed auth={auth} />
    </View>
  )
}

const styles = StyleSheet.create({
  scroll: {
    flex: 1,
  },
  page: {
    flex: 1,
  },
})
