import { withOAuth } from 'aws-amplify-react-native'
import React, { memo, useCallback, useRef, useState, useEffect } from 'react'
import {
  Dimensions,
  Text,
  View,
  Platform,
  ScrollView,
  StyleSheet,
  StatusBar,
  FlatList,
} from 'react-native'
import { Button } from 'react-native-paper'
import { AutocompleteDropdown } from 'react-native-autocomplete-dropdown'
import { CustomCheckbox } from '../../Components/CustomCheckbox'
import _ from 'lodash'
import { getData, postData } from '../../Components/useData'
import { useForm, Controller } from 'react-hook-form'
import { TextInput } from 'react-native-paper'

const FriendsTags = memo(({ auth, form }) => {
  const [loading, setLoading] = useState(false)
  const [suggestionsList, setSuggestionsList] = useState(null)
  const [selectedItem, setSelectedItem] = useState(null)
  const [newinterest, setNewInterest] = useState('')
  const [tagList, setTagList] = useState([])
  const dropdownController = useRef(null)
  const searchRef = useRef(null)

  const onChangeText = useCallback(
    async (q) => {
      setNewInterest(q.toLowerCase())
      const filterToken = q.toLowerCase()
      if (typeof q !== 'string') {
        setSuggestionsList(null)
        return
      }
      setLoading(true)

      const items = tagList.filter((item) =>
        item.toLowerCase().includes(filterToken)
      )

      const suggestions = items
        .filter((item) => item.toLowerCase())
        .map((item) => ({
          id: item,
          title: item,
        }))
      setSuggestionsList(suggestions)
      setLoading(false)
    },
    [tagList]
  )

  useEffect(() => {
    if (auth?.sub) {
      getData(
        'fit_tag_get',
        {
          userkey: auth?.sub,
        },
        (data) => {
          setTagList(data)
        }
      )
    }
  }, [auth])

  const onClearPress = useCallback(() => {
    setSuggestionsList(null)
  }, [])
  const onOpenSuggestionsList = useCallback((isOpened) => {}, [])

  return (
    <>
      <View
        style={{
          flexDirection: 'column',
          padding: 16,
          paddingBottom: 2,
          marginTop: 60,
        }}
      >
        <Text style={{ fontSize: 20 }}>Add Interests</Text>
      </View>

      <View
        style={[
          {
            paddingHorizontal: 10,
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: 20,
            marginBottom: 20,
          },
          Platform.select({ ios: { zIndex: 1 } }),
        ]}
      >
        <AutocompleteDropdown
          ref={searchRef}
          controller={(controller) => {
            dropdownController.current = controller
          }}
          // initialValue={'1'}
          direction={Platform.select({ ios: 'down' })}
          dataSet={suggestionsList}
          onChangeText={onChangeText}
          onSelectItem={(item) => {
            item && setSelectedItem(item.id)
          }}
          debounce={100}
          suggestionsListMaxHeight={200}
          onClear={onClearPress}
          //  onSubmit={(e) => onSubmitSearch(e.nativeEvent.text)}
          onOpenSuggestionsList={onOpenSuggestionsList}
          loading={loading}
          useFilter={false} // set false to prevent rerender twice
          textInputProps={{
            placeholder: 'Find an interest',
            autoCorrect: false,
            autoCapitalize: 'none',
            style: {
              borderRadius: 2,
              color: '#000000',
              paddingLeft: 18,
            },
          }}
          rightButtonsContainerStyle={{
            right: 20,
            height: 50,
            alignSelf: 'center',
          }}
          inputContainerStyle={{
            backgroundColor: '#F5F5F5',
            borderRadius: 8,
          }}
          suggestionsListContainerStyle={{
            backgroundColor: '#FFFFFF',
            borderRadius: 8,
            border: '1px solid #C2C2C2',
          }}
          containerStyle={{
            flexGrow: 1,
            flexShrink: 1,
            backgroundColor: '#FFFFFF',
            borderRadius: 8,
            border: '1px solid #C2C2C2',
          }}
          renderItem={(item, text) => (
            <Text style={{ color: '#fff', padding: 15 }}>{item.title}</Text>
          )}
          //   ChevronIconComponent={<Feather name="chevron-down" size={20} color="#fff" />}
          //   ClearIconComponent={<Feather name="x-circle" size={18} color="#fff" />}
          inputHeight={50}
          showChevron={false}
          closeOnBlur={false}
          //  showClear={false}
        />

        <Button
          style={{ margin: 10, height: 38, width: 150 }}
          mode={newinterest == '' ? 'disabled' : 'contained'}
          onPress={() => {
            let tags = form.getValues('taglist')
            form.setValue('taglist', _.union(tags, [newinterest]))
          }}
        >
          {'Create Interest'}
        </Button>
        <View style={{ width: 10 }} />
      </View>
      <ScrollView
        style={{
          marginHorizontal: 12,
          height: 140,
          padding: 2,
          backgroundColor: 'white',
          border: '1px solid #CCCCCC',
        }}
      >
        <FlatList
          data={suggestionsList}
          keyExtractor={(item, i) => item.title.toString()}
          renderItem={({ item, i }) => (
            <CustomCheckbox
              label={item.title}
              key={item.title}
              isChecked={_.includes(form.watch('taglist'), item.title)}
              onSetChecked={(isChecked) => {
                if (isChecked) {
                  form.clearErrors('taglist')
                }
                let tags = form.getValues('taglist')
                form.setValue('taglist', _.union(tags, [item.title]))
              }}
            />
          )}
        />
      </ScrollView>
      <View
        style={{
          flexDirection: 'row',
          minHeight: 40,
          padding: 10,
          flexWrap: 'wrap',
        }}
      >
        {form.watch('taglist')?.map((d) => (
          <Button
            style={{ margin: 2, height: 38 }}
            mode='outlined'
            icon='close'
            onPress={() => {
              form.setValue(
                'taglist',
                form.getValues('taglist').filter((e) => e != d)
              )
            }}
          >
            {d}
          </Button>
        ))}
        {/* <TextInput
          label='Create Interest'
          value={newinterest}
          onChangeText={(newinterest) => setNewInterest(newinterest)}
        /> */}
      </View>
    </>
  )
})

export default withOAuth(FriendsTags)
