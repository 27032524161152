import { useState, useEffect, useRef } from 'react'
import { StyleSheet, View, Text } from 'react-native'
import { schemeDark2 } from 'd3-scale-chromatic'
import { useTheme, Avatar } from 'react-native-paper'

const AVATARLIMIT = 5

export const Attendees = ({ auth, event, attendees }) => {
  const theme = useTheme()

  return (
    <>
      <View style={styles.avatars}>
        {attendees &&
          attendees.slice(1, AVATARLIMIT).map((d, i) => (
            <Avatar.Text
              key={i}
              size={32}
              label={d}
              labelStyle={{fontSize: 13}}
              color="white"
              style={{
                backgroundColor: schemeDark2[i],
                transform: [{ translateX: i * -6 }],
                zIndex: i,
              }}
            ></Avatar.Text>
          ))}
        <Text>
          {attendees &&
            attendees[0] > AVATARLIMIT &&
            `+ ${attendees[0] - AVATARLIMIT} more`}
        </Text>
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  avatars: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginRight: 10,
  },
})
