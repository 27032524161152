import { useState, useEffect } from 'react'
import { View, ScrollView, StyleSheet, Pressable } from 'react-native'
import { useTheme, Button } from 'react-native-paper'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { DatePickerModal, DatePickerInput } from 'react-native-paper-dates'
import { theme } from '../../theme'
import { getData, postData } from '../../Components/useData'

import Dropdown from '../../Components/Dropdown'

export const EventFilters = ({
  auth,
  selectedGroups,
  onSetSelectedGroups,
  selectedTags,
  onSetSelectedTags,
  start,
  end,
  updateDates,
  hideFilters,
  isFiltering,
  isLoading,
}) => {
  const theme = useTheme()

  const [defaultStart, setDefaultStart] = useState(new Date())
  const thirtyDays = new Date()
  thirtyDays.setDate(thirtyDays.getDate() + 30)
  const [defaultEnd, setDefaultEnd] = useState(thirtyDays)

  const [showGroupsDropDown, setShowGroupsDropDown] = useState(false)
  const [groups, setUserGroups] = useState([])
  const [tags, setTags] = useState([])

  const [attendeeMap, setAttendees] = useState(null)

  const [filteredEvents, setFilteredEvents] = useState([])

  const getGroups = () => {
    const userGroups = [].concat(
      auth.info?.groups,
      auth.info?.friendgroups,
      auth.info?.privategroups
    )

    setTags(auth.info?.tags)
    setUserGroups(userGroups)
  }

  const reset = () => {
    onSetSelectedTags(null)
    onSetSelectedGroups(null)
    updateDates(null, null)
  }

  useEffect(() => {
    if (auth?.oAuthUser) {
      getGroups()
    }
  }, [auth])

  // useEffect(() => {
  //   if (auth?.oAuthUser) {
  //     getGroups()
  //   }
  // }, [auth])

  useEffect(() => {
    if (auth?.sub) {
      getData(
        'fit_tag_get',
        {
          userkey: auth?.sub,
        },
        (data) => {
          setTags(data)
        }
      )
    }
  }, [auth])

  console.log(tags)
  return (
    <>
      <View style={styles.filters}>
        {groups.length > 0 && (
          <View style={styles.item}>
            <Dropdown
              label='Filter Groups'
              data={groups}
              selected={selectedGroups}
              onSelect={onSetSelectedGroups}
              multi={true}
              style={{ maxWidth: 20 }}
            />
          </View>
        )}
        {tags.length > 0 && (
          <View style={styles.item}>
            <Dropdown
              label='Filter Interests'
              data={tags}
              selected={selectedTags}
              onSelect={onSetSelectedTags}
              multi={true}
              style={{ maxWidth: 20 }}
            />
          </View>
        )}
        <View style={styles.item}>
          <DatePickerInput
            locale='en-US'
            label='From'
            mode='outlined'
            withDateFormatInLabel={false}
            value={start}
            onChange={(d) => updateDates(d, end)}
            inputMode='start'
            validRange={{ startDate: defaultStart, endDate: defaultEnd }}
            style={styles.datePick}
          />
        </View>
        <View style={styles.item}>
          <DatePickerInput
            locale='en-US'
            label='To'
            mode='outlined'
            withDateFormatInLabel={false}
            value={end}
            onChange={(d) => updateDates(start, d)}
            inputMode='start'
            validRange={{ startDate: start, endDate: defaultEnd }}
            style={styles.datePick}
          />
        </View>
        <View style={styles.buttonRow}>
          <Button onPress={reset} disabled={!isFiltering}>
            Reset
          </Button>
          <Button onPress={hideFilters} mode='contained'>
            Done
          </Button>
        </View>
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  filters: {
    flex: 1,
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    marginTop: 8,
    marginHorizontal: 21,
    height: 56,
    gap: 13,
  },
  buttonRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: 21,
  },
  item: {
    height: 56,
  },
})
