import React, { useEffect, useState } from 'react'
import { StyleSheet } from 'react-native'
import { Checkbox } from 'react-native-paper'

export const CustomCheckbox = ({
  label,
  onSetChecked,
  isChecked,
  position = 'leading',
  sublabel,
  styles,
}) => {
  return (
    <>
      <Checkbox.Item
        labelStyle={{ ...stylesmain.check, ...styles }}
        label={label}
        position={position}
        status={isChecked ? 'checked' : 'unchecked'}
        onPress={() => onSetChecked(!isChecked)}
      />
      {sublabel &&
        sublabel.map((sublabel, i) => (
          <Text variant='labelSmall' key={i}>
            {sublabel}
          </Text>
        ))}
    </>
  )
}

const stylesmain = StyleSheet.create({
  check: {
    textAlign: 'left',
  },
})
