import { useState } from 'react'
import { Button } from 'react-native-paper'
import { postData } from '../../Components/useData'

export const AcceptInviteButton = ({ auth, name, onAccept }) => {
  const [submitting, setSubmitting] = useState(false)

  const accept = () => {
    setSubmitting(true)
    var data = {
      grouplist: [name],
      userkey: auth.sub,
    }

    postData(
      'fit_fgroup_accept',
      data,
      () => onAccept('Accepted invite to ' + name),
      (err) => onAccept(err),
      () => setSubmitting(false)
    )
  }

  return (
    <>
      <Button mode='outlined' onPress={accept} disabled={submitting}>
        {submitting ? 'Accepting' : 'Accept'}
      </Button>
    </>
  )
}
