import { useState, useEffect, useRef } from 'react'
import { View } from 'react-native'
import { useTheme, Button, Text, HelperText } from 'react-native-paper'
import { postData, getData } from '../../Components/useData'

export const JoinButton = ({ auth, event, onJoined }) => {
	const theme = useTheme()

	const [joined, setJoined] = useState(event.messagetype == 'joined')
	const [error, setError] = useState(null)
	const [updating, setUpdating] = useState(false)

	function toggleJoin(eventid, shouldJoin) {
		setUpdating(true)

		var data = {
			userkey: auth.sub,
			eventid: eventid,
		}

		if (shouldJoin) {
			postData('fit_event_join',
				data,
				() => { setJoined(shouldJoin); onJoined(); },
				() => onError(shouldJoin),
				() => setUpdating(false)
			)
		} else {
			getData('fit_event_leave',
				data,
				() => { setJoined(shouldJoin); onJoined(); },
				() => onError(shouldJoin),
				() => setUpdating(false)
			)
		}
	}

	function onError(shouldJoin) {
		if (shouldJoin) {
			setError(
				'You joined this group after the event was created, so you cannot add to your schedule, but you can still attend.'
			)
			setJoined(null)
		} else {
			setError('Something went wrong, please try again.')
		}
	}

	return (
		<>
			<View>
				{error !== null && (
					<HelperText type="error"
						style={{
							color: theme.colors.error,
							marginBottom: 13,
							textAlign: 'right',
						}} >
						{error}
					</HelperText>
				)}
				{joined && <HelperText
					style={{
						textAlign: 'right',
					}} >
					You are attending.
				</HelperText>}
				<Button
					mode={joined ? "elevated" : 'contained'}
					icon={joined ? "minus-circle" : 'plus-circle'}
					uppercase
					disabled={updating}
					onPress={() => toggleJoin(event.eventid, !joined)}
				>
					{updating ? !!joined ? "Canceling" : 'Joining' : !!joined ? 'Cancel' : 'Join'}
				</Button>
			</View>
		</>
	)
}
