import { useState, useEffect } from 'react'
import { StyleSheet, View, Pressable } from 'react-native'
import { Portal, Modal, Text, Button, IconButton } from 'react-native-paper'

import { theme } from '../theme'

export const ButtonWithConfirmModal = ({
  auth,
  buttonText,
  buttonIcon,
  title,
  subtitle,
  confirmButtonText,
  onConfirm,
  buttonMode = 'contained',
  cancelText = 'cancel',
}) => {
  const [visible, setVisible] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const confirm = () => {
    setSubmitting(true)
    onConfirm()
    setTimeout(() => setVisible(false), 3000)
  }

  return (
    <>
      {!!buttonText ? (
        <Button
          mode={buttonMode}
          icon={buttonIcon}
          onPress={() => setVisible(true)}
        >
          {buttonText}
        </Button>
      ) : (
        <IconButton icon={buttonIcon} onPress={() => setVisible(true)} />
      )}

      <Portal>
        <Modal
          visible={visible}
          onDismiss={() => setVisible(false)}
          contentContainerStyle={styles.modal}
        >
          <View>
            <Text variant='headlineMedium'>{title}</Text>
            <Text variant='headlineSmall'>{subtitle}</Text>

            <View style={styles.buttons}>
              <Button onPress={() => setVisible(false)} disabled={submitting}>
                {cancelText}
              </Button>
              <Button
                mode='contained'
                disabled={submitting}
                style={styles.button}
                onPress={confirm}
              >
                {confirmButtonText}
              </Button>
            </View>
          </View>
        </Modal>
      </Portal>
    </>
  )
}

const styles = StyleSheet.create({
  modal: {
    backgroundColor: theme.colors.background,
    padding: 21,
    margin: 21,
    borderRadius: 25,
    justifyContent: 'space-between',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 21,
  },
  button: {
    flexGrow: 0,
    marginLeft: 13,
  },
})
