import { useState, useEffect } from 'react'
import { Platform } from 'react-native'

function getData(
  url,
  data,
  onSuccess = () => null,
  onError = () => null,
  onDone = () => null
) {
  if (url) {
    fetch(
      `${process.env.SERVER_URL}/${url}?appkey=${
        process.env.DATACAN_APP_KEY
      }&${new URLSearchParams(data)}`,
      { method: 'GET', headers: getHeaders() }
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json()
        } else {
          console.log('error')
          throw response
        }
      })
      .then((response) => onSuccess(response))
      .catch((err) => {
        catchErrors(err, onError)
      })
      .finally(onDone)
  }
}

function postData(
  url,
  uploadData,
  onSuccess = () => null,
  onError = () => null,
  onDone = () => null
) {
  uploadData.appkey = process.env.DATACAN_APP_KEY

  if (url) {
    fetch(`${process.env.SERVER_URL}/${url}`, {
      method: 'POST',
      body: JSON.stringify(uploadData),
      headers: getHeaders(),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json()
        } else {
          console.log('error')
          throw response
        }
      })
      .then((response) => onSuccess(response))
      .catch((err) => {
        console.log(err)
        catchErrors(err, onError)
      })
      .finally(onDone)
  }
}

function catchErrors(err, onError) {
  try {
    err.json().then((error) => {
      console.log(error)
      onError(error['message'] ? error['message'] : error, err.status)
    })
  } catch {
    onError(err['message'] ? err['message'] : err, err.status)
  }
}

function getHeaders() {
  let headers = { accept: 'application/json' }
  if (Platform.OS !== 'web') {
    headers = { ...headers, 'Content-Type': 'application/json' }
  }
  return headers
}

export { getData, postData }
