import { useState, useEffect } from 'react'
import { StyleSheet, ScrollView, View } from 'react-native'
import { Modal, Portal, Text, Button, TextInput } from 'react-native-paper'
import { Text as TextNative } from 'react-native'

import { theme } from '../../theme'
import { Load } from '../../Components/Load'
import { CustomCheckbox } from '../../Components/CustomCheckbox'

import { getData, postData } from '../../Components/useData'
import _ from 'lodash'
export const JoinRecommend = ({
  auth,
  visible,
  hide,
  onJoin,
  currentMembership,
  type,
}) => {
  const [search, setSearch] = useState('')
  const [error, setError] = useState()

  const [groups, setGroups] = useState([])
  const [filteredGroups, setFilteredGroups] = useState([])
  const [chosenGroups, setChosenGroups] = useState([])
  const [load, setLoad] = useState(true)
  const [updating, setUpdating] = useState(false)

  const getGroups = () => {
    getData(
      'fit_group_recommend',
      { userkey: auth?.sub, expanded: 'true' },
      (response) => setGroups(response),
      // (response) => setGroups(test),
      () => null,
      () => setLoad(false)
    )
  }

  // console.log(groups)

  const updateChosen = (group, isChecked) => {
    const i = chosenGroups.indexOf(group)
    const updatedGroups = [...chosenGroups]

    if (isChecked && i === -1) {
      updatedGroups.push(group)
    } else if (!isChecked && i !== -1) {
      updatedGroups.splice(i, 1)
    }
    setChosenGroups(updatedGroups)
  }
  // console.log(chosenGroups)

  const submit = () => {
    postMembershipUpdate(chosenGroups, true)
    // postMembershipUpdate(currentMembership.filter((m) => !chosenGroups.includes(m)), false)
  }

  console.log(chosenGroups)

  function postMembershipUpdate(groupList, isJoining) {
    setUpdating(true)

    let globalList = groupList
      .filter((g) => g.type === 'global')
      .map((g) => g.name)

    var data1 = {
      userkey: auth?.sub,
      grouplist: globalList,
    }

    console.log(globalList)

    postData(
      'fit_group_add',
      data1,
      () => onJoin(groupList.length),
      () => null,
      () => {
        let publicList = groupList
          .filter((g) => g.type === 'public')
          .map((g) => g.name)

        var data2 = {
          userkey: auth?.sub,
          grouplist: publicList,
        }
        console.log(publicList)

        postData(
          'fit_fgroup_add',
          data2,
          () => onJoin(groupList.length),
          () => null,
          () => setUpdating(false)
        )
      }
    )
  }

  useEffect(() => {
    auth?.oAuthUser && getGroups()
    // setGroups(test)
  }, [auth])

  useEffect(() => {
    filter()
  }, [groups, search])

  function filter() {
    if (search.length === 0) {
      setFilteredGroups(groups)
    } else {
      setFilteredGroups(
        groups.filter((g) =>
          g.search.toLowerCase().includes(search.toLowerCase())
        )
      )
    }
  }

  return (
    <>
      <Portal>
        <Modal
          visible={visible}
          onDismiss={hide}
          contentContainerStyle={styles.modal}
          style={{ flexGrow: 1 }}
        >
          {load ? (
            <Load />
          ) : (
            <View style={{ height: '100%' }}>
              <Text variant='headlineMedium'>Select groups to join</Text>
              <TextInput
                style={{ marginVertical: 21 }}
                label='Search'
                value={search}
                onChangeText={(text) => setSearch(text)}
                mode='outlined'
                error={error}
              />

              <ScrollView style={{ flexGrow: 1 }}>
                {_.isEmpty(groups) ? (
                  <>
                    <View>
                      <Text style={{ fontSize: '18px' }}>
                        {"You don't have a matching tag."}
                      </Text>
                    </View>
                  </>
                ) : (
                  filteredGroups
                    .filter((g) => !currentMembership.includes(g.search))
                    .map((g, i) => (
                      <CustomCheckbox
                        label={g.name}
                        key={g.name}
                        isChecked={chosenGroups.includes(g)}
                        onSetChecked={(isChecked) => updateChosen(g, isChecked)}
                      />
                    ))
                )}
                <View style={{ marginVertical: 20 }}>
                  <TextNative style={{ fontSize: '18px', fontWeight: '600' }}>
                    {'Recommendations based on your tags in settings.'}
                  </TextNative>
                </View>
              </ScrollView>

              <View style={styles.buttons}>
                <Button style={styles.button} onPress={hide}>
                  Cancel
                </Button>
                <Button
                  style={styles.button}
                  mode='contained'
                  disabled={updating || chosenGroups.length === 0}
                  onPress={submit}
                >
                  {updating ? 'Joining' : 'Join'}
                </Button>
              </View>
            </View>
          )}
        </Modal>
      </Portal>
    </>
  )
}

const styles = StyleSheet.create({
  modal: {
    backgroundColor: theme.colors.background,
    padding: 21,
    margin: 21,
    borderRadius: 25,
    height: '90%',
    justifyContent: 'space-between',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 21,
  },
  button: {
    flexGrow: 0,
    marginLeft: 13,
  },
})
