import { useState, useEffect } from 'react'
import { useNavigation, CommonActions } from '@react-navigation/native';

import { ButtonWithConfirmModal } from '../../../Components/ButtonWithConfirmModal'
import { postData } from '../../../Components/useData'

export const DeleteGroup = ({ auth, name, setSnack }) => {
  const navigation = useNavigation()

  const deleteGroup = () => {
    var data = {
      userkey: auth?.sub,
      groupname: name,
    }

    postData(
      'fit_groupmgr_group_delete',
      data,
      onDelete,
      () => setSnack('Could not delete group')
    )
  }

  const onDelete = (response) => {
		navigation.dispatch(state => {
			const new_state = state
			const group_route = new_state.routes.find(r => r.name === 'Group');
			group_route.params = null
			delete group_route.state
			return CommonActions.reset(new_state);
		});
		navigation.navigate("Friends", { screen: "Groups", params: { snack: "Successfully deleted group." } })
	}

  return (
    <ButtonWithConfirmModal
      buttonText='Delete Group'
      buttonIcon='close-circle'
      title='Are you sure you want to delete this group?'
      subtitle='This cannot be undone.'
      confirmButtonText='Delete'
      onConfirm={deleteGroup}
    />
  )
}
