import { useState, useRef } from 'react'
import { StyleSheet, View, ScrollView } from 'react-native'
import { useCounter } from 'usehooks-ts'
import { Modal, Portal, Button, TextInput, Text } from 'react-native-paper'

import { theme } from '../../theme'
import { GroupDetailsForm } from './GroupDetailsForm'

export const CreateGroupModal = ({ auth, visible, hide, showMessage }) => {
  function onSubmit() {
    showMessage('success', 'Group created successfully')
    hide()
  }

  return (
    <Portal>
      <Modal
        visible={visible}
        onDismiss={hide}
        contentContainerStyle={styles.modal}
      >
        <Text
          style={{ textAlign: 'center', paddingBottom: 34 }}
          variant='headlineMedium'
        >
          {'Create a friend group'}
        </Text>
        <ScrollView>
          <GroupDetailsForm auth={auth} onSubmit={onSubmit} onCancel={hide} />
        </ScrollView>
      </Modal>
    </Portal>
  )
}

const styles = StyleSheet.create({
  modal: {
    backgroundColor: theme.colors.background,
    margin: 21,
    padding: 21,
    borderRadius: 25,
    maxHeight: '90%',
  },
})
