import { withOAuth } from 'aws-amplify-react-native'
import React, { memo, useCallback, useRef, useState } from 'react'
import {
  Dimensions,
  Text,
  View,
  Platform,
  ScrollView,
  StyleSheet,
  StatusBar,
  FlatList,
} from 'react-native'
import { Button } from 'react-native-paper'
import { AutocompleteDropdown } from 'react-native-autocomplete-dropdown'
import { CustomCheckbox } from '../../Components/CustomCheckbox'
import _ from 'lodash'

const RegisterTags = memo(({ auth, tagList, form }) => {
  const [loading, setLoading] = useState(false)
  const [suggestionsList, setSuggestionsList] = useState(null)
  const [selectedItem, setSelectedItem] = useState(null)
  const dropdownController = useRef(null)
  const searchRef = useRef(null)

  const getSuggestions = useCallback(
    async (q) => {
      const filterToken = q.toLowerCase()
      if (typeof q !== 'string') {
        setSuggestionsList(null)
        return
      }
      setLoading(true)

      const items = tagList.filter((item) =>
        item.toLowerCase().includes(filterToken)
      )

      const suggestions = items
        .filter((item) => item.toLowerCase())
        .map((item) => ({
          id: item,
          title: item,
        }))
      setSuggestionsList(suggestions)
      setLoading(false)
    },
    [tagList]
  )

  const onClearPress = useCallback(() => {
    setSuggestionsList(null)
  }, [])
  const onOpenSuggestionsList = useCallback((isOpened) => {}, [])
  // console.log(
  //   _.keys(form.watch('tags')).filter((d) => form.watch('tags')[d] == 'checked')
  // )

  return (
    <>
      <View style={{ flexDirection: 'column', padding: 16, marginTop: 10 }}>
        <Text style={{ fontSize: 20 }}>Select Your Interests</Text>
        <Text style={{ fontSize: 15, marginTop: 6 }}>
          Find your communities! (required)
        </Text>
      </View>

      <View
        style={[
          {
            paddingHorizontal: 10,
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: 20,
            marginBottom: 20,
          },
          Platform.select({ ios: { zIndex: 1 } }),
        ]}
      >
        <AutocompleteDropdown
          ref={searchRef}
          controller={(controller) => {
            dropdownController.current = controller
          }}
          direction={Platform.select({ ios: 'down' })}
          dataSet={suggestionsList}
          onChangeText={getSuggestions}
          onSelectItem={(item) => {
            item && setSelectedItem(item.id)
          }}
          debounce={100}
          suggestionsListMaxHeight={200}
          onClear={onClearPress}
          //  onSubmit={(e) => onSubmitSearch(e.nativeEvent.text)}
          onOpenSuggestionsList={onOpenSuggestionsList}
          loading={loading}
          useFilter={false} // set false to prevent rerender twice
          textInputProps={{
            placeholder: 'Find an interest',
            autoCorrect: false,
            autoCapitalize: 'none',
            style: {
              borderRadius: 2,
              color: '#000000',
              paddingLeft: 18,
            },
          }}
          rightButtonsContainerStyle={{
            right: 20,
            height: 50,
            alignSelf: 'center',
          }}
          inputContainerStyle={{
            backgroundColor: '#F5F5F5',
            borderRadius: 8,
          }}
          suggestionsListContainerStyle={{
            backgroundColor: '#FFFFFF',
            borderRadius: 8,
            border: '1px solid #C2C2C2',
          }}
          containerStyle={{
            flexGrow: 1,
            flexShrink: 1,
            backgroundColor: '#FFFFFF',
            borderRadius: 8,
            border: '1px solid #C2C2C2',
          }}
          renderItem={(item, text) => (
            <Text style={{ color: '#fff', padding: 15 }}>{item.title}</Text>
          )}
          //   ChevronIconComponent={<Feather name="chevron-down" size={20} color="#fff" />}
          //   ClearIconComponent={<Feather name="x-circle" size={18} color="#fff" />}
          inputHeight={50}
          showChevron={false}
          closeOnBlur={false}
          //  showClear={false}
        />
        <View style={{ width: 10 }} />
      </View>
      <ScrollView
        style={{
          marginHorizontal: 12,
          height: 200,
          padding: 2,
          backgroundColor: 'white',
          border: '1px solid #CCCCCC',
        }}
      >
        <FlatList
          data={
            suggestionsList
              ? suggestionsList
              : tagList.map((d) => ({ id: d, title: d }))
          }
          keyExtractor={(item, i) => item.title.toString()}
          renderItem={({ item }) => (
            <CustomCheckbox
              styles={{ fontSize: 13, paddingHorizontal: 6 }}
              label={item.title}
              key={item.title}
              isChecked={form.watch('tags.' + item.title) == 'checked'}
              onSetChecked={(isChecked) => {
                if (isChecked) {
                  form.clearErrors('tags')
                }
                form.setValue(
                  'tags.' + item.title,
                  isChecked ? 'checked' : 'unchecked'
                )
              }}
            />
          )}
        />
      </ScrollView>
      <View
        style={{
          flexDirection: 'row',
          minHeight: 40,
          padding: 10,
          flexWrap: 'wrap',
          marginBottom: 10,
        }}
      >
        {_.keys(form.watch('tags'))
          .filter((d) => form.watch('tags')[d] == 'checked')
          .map((d) => (
            <Button
              style={{ margin: 2 }}
              labelStyle={{ fontSize: 13, padding: 0 }}
              mode='outlined'
              icon='close'
              onPress={() => {
                form.setValue('tags.' + d, 'unchecked')
              }}
            >
              {d}
            </Button>
          ))}
      </View>
    </>
  )
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: StatusBar.currentHeight,
  },

  text: {
    fontSize: 42,
  },
})

export default withOAuth(RegisterTags)
