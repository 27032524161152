import React, { useEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { useForm, Controller } from 'react-hook-form'
import { HelperText } from 'react-native-paper'
import { DatePickerInput } from 'react-native-paper-dates';

import ErrorBoundary from "./ErrorBoundary"

function FormDateInput({ form, fieldName, label, startdatelimit, helper, error, required=true, onPress }) {

  const [showModal, toggleModal] = React.useState(false);

  return (
    <View>
      <ErrorBoundary fallback={<p></p>}>
        <Controller
          name={fieldName}
          control={form.control}
          rules={{ required: required }}
          render={({ field }) =>
            <DatePickerInput
              style={{ marginTop: 13 }}
              locale="en-US"
              label={label}
              mode="outlined"
              value={form.watch(fieldName)}
              onChange={(d) => form.setValue(fieldName, d)}
              inputMode="start"
              validRange={{startDate: startdatelimit}}
            />
          }
        />
        <HelperText type={error ? "error" : "info"} visible={error || helper}>
          {error ? "Required" : helper}
        </HelperText>
      </ErrorBoundary>
    </View>)
}

export default FormDateInput