import { useState, useEffect } from 'react'
import { View, StyleSheet, SectionList } from 'react-native'
import { Text, Button } from 'react-native-paper'

import { Load } from '../../Components/Load'
import { getData } from '../../Components/useData'
import { Event } from './Event'
import { theme } from '../../theme'

export const EventList = ({
  auth,
  events,
  isLoading,
  updateEvents,
  toggleFilters,
  start,
  end,
  isFiltering,
}) => {
  const [attendeeMap, setAttendees] = useState(null)
  const [sectionedEvents, setSectionedEvents] = useState([])

  useEffect(() => {
    if (auth?.oAuthUser) {
      getAttendees()
    }
  }, [auth])

  useEffect(() => {
    sectionEvents()
  }, [events])

  const getAttendees = () => {
    getData('fit_event_totals', { userkey: auth.sub }, (response) =>
      setAttendees(response)
    )
  }

  const sectionEvents = () => {
    const arr = [...new Set(events.map((e) => getHeaderDate(e.datetime)))]
    setSectionedEvents(
      arr.map((d) => {
        return {
          date: d,
          data: events.filter((e) => getHeaderDate(e.datetime) === d),
        }
      })
    )
  }

  function getHeaderDate(datetime) {
    return (
      datetime.weekdayShort + ' ' + datetime.monthShort + ' ' + datetime.day
    )
  }

  return (
    <>
      <View style={styles.filterBar}>
        {isLoading ? (
          <Text variant='titleMedium'>Loading...</Text>
        ) : (
          <>
            <Text variant='titleMedium'>
              {start} - {end}
            </Text>
            <Button
              onPress={toggleFilters}
              mode={isFiltering ? 'contained' : 'text'}
            >
              {isFiltering ? 'Filtering' : 'Filter'}
            </Button>
          </>
        )}
      </View>
      {isLoading ? (
        <Load />
      ) : (
        <View style={{ ...styles.list }}>
          {events.length > 0 ? (
            <SectionList
              sections={sectionedEvents}
              keyExtractor={(item, index) =>
                item.eventid + item.occurrence_timestamp.toString()
              }
              stickySectionHeadersEnabled={true}
              renderSectionHeader={({ section }) => (
                <View style={{ ...styles.subhead }}>
                  <Text variant='titleMedium'>{section.date}</Text>
                </View>
              )}
              renderItem={({ item, index }) => (
                <>
                  <Event
                    event={item}
                    auth={auth}
                    attendees={attendeeMap && attendeeMap[item.eventid]}
                    updateEvents={updateEvents}
                  />
                </>
              )}
            />
          ) : (
            <View style={styles.empty}>
              <Text variant='titleLarge' style={styles.emptyContent}>
                Join more groups for more events
              </Text>
            </View>
          )}
        </View>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  list: {
    flexGrow: 1,
    flexShrink: 1,
    paddingTop: 13,
  },
  subhead: {
    textTransform: 'uppercase',
    zIndex: 100,
    paddingHorizontal: 29,
    paddingVertical: 8,
    backgroundColor: theme.colors.tertiaryContainer,
  },
  filterBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginHorizontal: 13,
    marginVertical: 8,
  },
  empty: {
    display: 'flex',
  },
  emptyContent: {
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.5)',
    marginTop: 44,
  },
})
