import { StyleSheet, View } from 'react-native'
import { TextInput, Switch, IconButton } from 'react-native-paper'

export const Option = ({
  icon,
  label,
  value,
  isChecked,
  onToggle,
  onUpdate,
}) => {
  return (
    <View style={styles.option}>
      <TextInput
        style={styles.input}
        left={<TextInput.Icon icon={icon} />}
        label={label}
        value={value}
        disabled={label !== 'SMS'}
        onChangeText={onUpdate}
        autoComplete={label === 'SMS' ? 'tel' : 'email'}
        inputMode={label === 'SMS' ? 'tel' : 'email'}
      />
      <Switch
        style={styles.switch}
        onValueChange={(val) => onToggle(val)}
        value={isChecked}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  option: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: 13,
  },
  input: {
    backgroundColor: 'transparent',
    flexGrow: 1,
  },
  switch: {
    marginLeft: 21,
  },
})
