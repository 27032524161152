import { View, Pressable, Text, StyleSheet } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useTheme } from 'react-native-paper'

import { ButtonWithConfirmModal } from '../../Components/ButtonWithConfirmModal'
import { getData } from '../../Components/useData'

export const DeleteAccountButton = ({ auth, logout }) => {
  const navigation = useNavigation()
  const theme = useTheme()

  const deleteAccount = () => {
    getData('fit_account_delete', { userkey: auth?.sub }, logout, logout)
  }
  return (
    <ButtonWithConfirmModal
      buttonText='Delete account'
      buttonIcon='close-circle'
      buttonMode='elevated'
      title={`Are you sure you want to delete your account?`}
      subtitle={
        'This will permanently delete all of your data. You can sign up again with your Harvard email.'
      }
      confirmButtonText='Delete Account'
      onConfirm={deleteAccount}
    />
  )
}
